import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const Dropdown = ({ className, isMulti, options, value, onChange }) => (
  <S.Dropdown className={className}>
    <S.ReactSelect
      value={value}
      onChange={onChange}
      options={options}
      className="react-select-container"
      classNamePrefix="react-select"
      isSearchable={false}
      isMulti={isMulti}
    />
  </S.Dropdown>
)

Dropdown.propTypes = {
  className: PropTypes.string.isRequired,
  isMulti: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired
}

Dropdown.defaultProps = {}

export default Dropdown
