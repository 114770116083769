import * as PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import breaks from "remark-breaks"

import { useScrollTo } from "../../helpers"
import { brandColorData } from "../../styles/brandsColor"
import PageTemplate from "../PageTemplate"
import BrandHeader from "../../components/BrandHeader"
import * as S from "./style"

const MarketPage = ({
  market: { countryCode, brandId, marketTitle, seoTitle, seoDescription, description, content },
  brandName,
  slug,
  brandPageLogo,
  translations
}) => {
  useScrollTo()

  const colours = brandColorData[brandId]

  const brand = {
    brandName,
    brandPageLogo,
    slug
  }

  const market = {
    countryCode,
    brandId,
    marketTitle
  }

  return (
    <PageTemplate>
      <Helmet>
        <title data-react-helmet="true">{seoTitle}</title>
        <meta name="description" content={seoDescription} data-react-helmet="true" />
      </Helmet>

      <S.MarketContainer backgroundColor={colours.background}>
        <BrandHeader brand={brand} translations={translations} market={market} />
        <S.Content>
          {description && <S.Description plugins={[breaks]}>{description}</S.Description>}
          <S.ContentInner plugins={[breaks]}>{content}</S.ContentInner>
        </S.Content>
      </S.MarketContainer>
    </PageTemplate>
  )
}

MarketPage.propTypes = {
  market: PropTypes.objectOf(PropTypes.any),
  countryCode: PropTypes.string,
  brandId: PropTypes.string,
  marketTitle: PropTypes.string,
  brandName: PropTypes.string,
  brandPageLogo: PropTypes.string,
  slug: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  translations: PropTypes.any
}

MarketPage.defaultProps = {
  market: {},
  countryCode: null,
  brandId: null,
  marketTitle: null,
  brandName: null,
  brandPageLogo: null,
  slug: null,
  seoTitle: null,
  seoDescription: null,
  description: null,
  content: null,
  translations: {}
}

export default MarketPage
