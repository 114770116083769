import styled, { css } from "styled-components"

import Button from "../Button"

export const Options = styled.div`
  position: absolute;
  top: ${props => props.top}px;
  right: 0;

  display: flex;
  flex-direction: column;

  min-width: 120%;
  padding-top: 5px;

  white-space: nowrap;

  transition: opacity 0.15s ease-in-out;

  ${props =>
    props.isVisible
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`

export const Option = styled.a`
  box-sizing: border-box;

  max-height: ${props => props.maxHeight}px;

  padding: 2px 15px;

  background-color: #fff;

  color: ${props => props.theme.colours.black};
  font-size: 1em;
  text-transform: uppercase;
  text-decoration: none;
  text-align: right;

  border: none;

  cursor: pointer;

  transition: background-color 0.1s ease-in-out;

  p {
    transition: transform 0.1s ease-in-out;
  }

  :hover {
    background-color: ${props => props.theme.colours.black};
    color: #fff;

    p {
      transform: translateX(-3px);
    }
  }
`

export const RegisterButton = styled(Button)`
  margin-right: 20px;
`
