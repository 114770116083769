import PropTypes from "prop-types"
import React from "react"

import { Link } from "react-router-dom"

import { brandColorData } from "../../styles/brandsColor"
import downIcon from "../../assets/icons/icon-chevrondown.svg"
import * as S from "./style"

const BrandHeader = ({ brand, translations, market }) => {
  const { brandName, slug, brandPageLogo } = brand

  const { countryCode, brandId, marketTitle } = market

  const isMarketPage = Object.keys(market).length !== 0

  const colours = brandColorData[slug]
  return (
    <S.BrandHeader colour={colours.background}>
      <S.HeaderInner isMarketPage={isMarketPage}>
        <S.HeaderContent>
          <S.Breadcrumbs textColor={colours.text}>
            <S.BreadCrumbsPath>
              <span>
                <Link to={`/${translations.brands}`}>{translations.brands}</Link>
              </span>
              <span>{`>`}</span>
              <span>{isMarketPage ? <Link to={`/${translations.brands}/${brandId}`}>{brandName}</Link> : brandName}</span>
              {isMarketPage && (
                <>
                  <span>{`>`}</span>
                  <span>{countryCode}</span>
                </>
              )}
            </S.BreadCrumbsPath>
            <S.BreadCrumbsArrow>
              <Link to={isMarketPage ? `/${translations.brands}/${brandId}` : `/${translations.brands}`}>
                <img src={downIcon} alt="" />
              </Link>
            </S.BreadCrumbsArrow>
          </S.Breadcrumbs>
          <S.MarketTitle textColor={colours.text}>{marketTitle}</S.MarketTitle>
        </S.HeaderContent>
        <S.Logo src={brandPageLogo} alt="Brand Logo" />
      </S.HeaderInner>
    </S.BrandHeader>
  )
}

BrandHeader.propTypes = {
  brand: PropTypes.objectOf(PropTypes.any).isRequired,
  market: PropTypes.objectOf(PropTypes.any),
  translations: PropTypes.any.isRequired
}

BrandHeader.defaultProps = {
  market: {}
}

export default BrandHeader
