import { keyframes } from "styled-components"

export const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const fadeInOutSubtle = keyframes`
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`
