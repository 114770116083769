import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const FaqItem = ({ data: { question, answer } }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <S.FaqItem onClick={() => setIsOpen(!isOpen)}>
      <S.Question isOpen={isOpen}>
        <p>{question}</p>
      </S.Question>
      <S.Answer isOpen={isOpen}>{answer}</S.Answer>
    </S.FaqItem>
  )
}

FaqItem.propTypes = {
  data: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired
  }).isRequired
}

export default FaqItem
