import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const RadialPercentage = ({ title, value, shouldAnimate, animationDelay }) => {
  const startValue = 2 * Math.PI * ((0 * value) / 100)
  const endValue = 2 * Math.PI * ((45 * value) / 100)

  const staticValue = 2 * Math.PI * 45

  return (
    <S.RadialPercentageBlock>
      <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
        <S.RadialPercentageCircleInner cx="50%" cy="50%" r="36" />
        <S.RadialPercentageCircleOuter
          cx="50%"
          cy="50%"
          r="45"
          dashArray={`0 ${staticValue}`}
          startValue={startValue}
          endValue={endValue}
          staticValue={staticValue}
          shouldAnimate={shouldAnimate}
          animationDelay={animationDelay}
          transform="rotate(142, 50 50)"
        />

        <S.SvgText x="50" y="52">{`${value}%`}</S.SvgText>
      </svg>
      <h3>{title}</h3>
    </S.RadialPercentageBlock>
  )
}

RadialPercentage.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  shouldAnimate: PropTypes.bool.isRequired,
  animationDelay: PropTypes.number.isRequired
}

export default RadialPercentage
