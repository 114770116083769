import PropTypes from "prop-types"
import React from "react"

import HomeSection from "../HomeSection"
import RadialPercentage from "../RadialPercentage"
import Title from "../Title"
import * as S from "./style"

const isElementInViewport = element =>
  element.getBoundingClientRect().top + element.clientHeight / 10 <= (window.innerHeight || document.documentElement.clientHeight)

const Commission = ({
  data: {
    config,
    content: { title, text, commissions }
  }
}) => {
  const [shouldAnimate, setShouldAnimate] = React.useState(false)

  const sectionRef = React.useRef(null)

  React.useEffect(() => {
    const scrollHandler = () => setShouldAnimate(isElementInViewport(sectionRef.current))

    window.addEventListener("scroll", scrollHandler)

    return () => window.removeEventListener("scroll", scrollHandler)
  }, [])

  return (
    <HomeSection id="commission" config={config}>
      <S.Commission>
        <S.TextContainer>
          <Title>{title}</Title>
          <S.Markdown source={text} />
        </S.TextContainer>
        <S.RadialPercentageContainer ref={sectionRef}>
          {commissions.map((commission, ind) => (
            <RadialPercentage
              key={commission.title}
              title={commission.title}
              value={commission.value}
              shouldAnimate={shouldAnimate}
              animationDelay={ind / 6}
            />
          ))}
        </S.RadialPercentageContainer>
      </S.Commission>
    </HomeSection>
  )
}

Commission.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      bgColor: PropTypes.string,
      bgTitle: PropTypes.string,
      bgTitleColor: PropTypes.string,
      outboundLink: PropTypes.string,
      outboundText: PropTypes.string,
      outboundLinkColor: PropTypes.string
    }).isRequired,
    content: PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      commissions: PropTypes.arrayOf.isRequired
    }).isRequired
  }).isRequired
}

export default Commission
