import * as PropTypes from "prop-types"
import React from "react"

import FaqItem from "../../components/FaqItem"
import { useScrollTo } from "../../helpers"
import PageTemplate from "../PageTemplate"
import * as S from "./style"

const FaqPage = ({ faqs }) => {
  useScrollTo()

  return (
    <PageTemplate>
      <S.FaqContainer>
        <S.AboutFaq>
          <h1>Frequently asked questions</h1>
          <p>
            Everything you need to know about LeoVegas. You can&apos;t find an answer ? <a href="/#contact">Contact us.</a>
          </p>
        </S.AboutFaq>
        <S.QuestionsAnswers>
          {faqs.map((entry, ind) => (
            <FaqItem key={ind} data={entry} />
          ))}
        </S.QuestionsAnswers>
      </S.FaqContainer>
    </PageTemplate>
  )
}

FaqPage.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.any).isRequired
}

export default FaqPage
