import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"

import * as S from "./style"

const Hero = ({ mobileVideo, desktopVideo }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  const videoRef = useRef(null)

  const handleScroll = () => {
    if (!videoRef.current) return

    if (window.scrollY < 100) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768)

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <S.Hero className="hero">
      {isMobile ? (
        <S.HeroVideo autoPlay muted loop playsInline isMobile ref={videoRef}>
          <source src={mobileVideo} type="video/mp4" />
        </S.HeroVideo>
      ) : (
        <S.HeroVideo autoPlay muted loop playsInline>
          <source src={desktopVideo} type="video/mp4" />
        </S.HeroVideo>
      )}
    </S.Hero>
  )
}

Hero.propTypes = {
  mobileVideo: PropTypes.string.isRequired,
  desktopVideo: PropTypes.string.isRequired
}

export default Hero
