import PropTypes from "prop-types"
import React from "react"
import removeMd from "remove-markdown"

import { resizeContentfulImage, createBrandString } from "../../helpers"
import * as S from "./style"

// const trimTitle = title => {
//   const maxLength = 55

//   return title.length > maxLength ? `${title.substr(0, maxLength - 1)}...` : title
// }

const trimContent = (content, isSmall) => {
  const maxLength = isSmall ? 120 : 130

  return content.length > maxLength ? `${content.substr(0, maxLength - 1)}...` : content
}

const createLink = slug => `/news/${slug}`

const NewsListItem = ({ data: { categories, newsTitle, newsText, slug, newsThumbnail }, isSmall, translations }) => {
  const imageSize = isSmall ? 130 : 180
  const imageQuality = isSmall ? 60 : 75

  return (
    <S.ArticleLink to={createLink(slug)}>
      <S.NewsListItem>
        <S.ImageContainer>
          <S.Image src={resizeContentfulImage(newsThumbnail, imageSize, imageSize, imageQuality)} alt="Article" />
        </S.ImageContainer>
        <S.ArticleContainer>
          <p>{createBrandString(categories)}</p>
          <S.ArticlePreview>
            <S.Title>{newsTitle}</S.Title>
            <p>{trimContent(removeMd(newsText), isSmall)}</p>
          </S.ArticlePreview>
          <S.ReadMore>{translations.readMore}</S.ReadMore>
        </S.ArticleContainer>
      </S.NewsListItem>
    </S.ArticleLink>
  )
}

NewsListItem.propTypes = {
  data: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    newsTitle: PropTypes.string.isRequired,
    newsText: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    newsThumbnail: PropTypes.string.isRequired
  }).isRequired,
  isSmall: PropTypes.bool,
  translations: PropTypes.objectOf(PropTypes.any)
}
NewsListItem.defaultProps = {
  translations: {},
  isSmall: false
}

export default NewsListItem
