import React from "react"
import PropTypes from "prop-types"

import * as S from "./style"
import NewsTileSmall from "../NewsTileSmall"
import Title from "../Title"
import OutboundLink from "../OutboundLink"

const News = ({ title, news, viewAllLink }) => {
  return (
    <S.News>
      <S.Inner>
        <Title isInverted>{title}</Title>
        <S.NewsContainer>
          {news.map(({ slug, newsTitle, newsThumbnail, category }, index) => (
            <NewsTileSmall
              key={index}
              imageUrl={`${newsThumbnail}?fm=webp&q=80&w=284`}
              title={newsTitle}
              href={`/news/${slug}`}
              brands={category.map(({ categoryName }) => categoryName)}
            />
          ))}
        </S.NewsContainer>
        {viewAllLink && (
          <OutboundLink url={viewAllLink.url} colour="#fff">
            {viewAllLink.label}
          </OutboundLink>
        )}
      </S.Inner>
    </S.News>
  )
}

export default News

News.propTypes = {
  title: PropTypes.string.isRequired,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      brandLogo: PropTypes.string.isRequired
    })
  ).isRequired,
  viewAllLink: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
}
