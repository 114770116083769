import styled from "styled-components"

import { constrictedContainer } from "../../styles/globalStyle"

export const BrandContainer = styled.div`
  ${constrictedContainer};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  flex: 1;

  margin: 120px auto;
`

export const BrandInner = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
  gap: 20px;

  @media (min-width: 512px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
`
