import styled from "styled-components"

import { constrictedContainer } from "../../styles/globalStyle"

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.testimonials.headerBgColour};
`
export const HeaderContent = styled.div`
  ${constrictedContainer};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const TestimonialsContainer = styled.div`
  background: url("/assets/images/seamless.jpg");
  background-attachment: fixed;

  margin: 120px 0;
`

export const TestimonialsContainerInner = styled.div`
  ${constrictedContainer};
  position: relative;

  margin-bottom: 60px;

  display: grid;
  grid-auto-flow: row;

  grid-template-columns: minmax(0, 1fr);
  gap: 32px;

  padding-top: 30px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 32px;
  }
`
