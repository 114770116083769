import React from "react"
import PropTypes from "prop-types"

import * as S from "./style"
import BrandTileSmall from "../BrandTileSmall"
import Title from "../Title"
import OutboundLink from "../OutboundLink"

const Brands = ({ title, brands, viewAllLink }) => {
  return (
    <S.Brands>
      <S.Inner>
        <Title isInverted>{title}</Title>
        <S.BrandContainer>
          {brands.map(({ slug, brandLogo }, index) => (
            <BrandTileSmall key={index} logoUrl={`${brandLogo}?fm=webp&q=75&w=194`} href={`/brands/${slug}`} />
          ))}
        </S.BrandContainer>
        {viewAllLink && (
          <OutboundLink url={viewAllLink.url} colour="#fff">
            {viewAllLink.label}
          </OutboundLink>
        )}
      </S.Inner>
    </S.Brands>
  )
}

export default Brands

Brands.propTypes = {
  title: PropTypes.string.isRequired,
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      brandLogo: PropTypes.string.isRequired
    })
  ).isRequired,
  viewAllLink: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
}
