import styled from "styled-components"
import { Link } from "react-router-dom"

export const OutboundLink = styled(Link).attrs(props => ({
  color: props.outboundlinkcolor || "#000"
}))`
  font-family: ${props => props.theme.fonts.primary};
  /* font-size: 1.3125em; */
  font-weight: 700;
  color: ${props => props.color};
  text-decoration: none;
  text-align: center;
  width: fit-content;

  margin-top: 25px;

  :hover {
    text-decoration: underline;
  }
`
