import PropTypes from "prop-types"
import React from "react"

import { downloadFolder } from "../../helpers"
import * as S from "./style"

const DownloadableItem = ({ brandLogo, brandName, url, fileName }) => {
  return (
    <S.DownloadableLogo onClick={() => downloadFolder(url, fileName)}>
      <img src={brandLogo} alt={brandName} />
      <S.DownloadTextContainer>
        <S.DownloadText>
          <span>Download Logos</span>
        </S.DownloadText>
      </S.DownloadTextContainer>
    </S.DownloadableLogo>
  )
}

DownloadableItem.propTypes = {
  brandLogo: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired
}

export default DownloadableItem
