import React from "react"
import PropTypes from "prop-types"

import OutboundLink from "../OutboundLink"
import * as S from "./style"

import Title from "../Title"
import TestimonialItem from "../TestimonialItem"

const Testimonials = ({ title, testimonials, viewAllLink, readMoreLink }) => {
  return (
    <S.Testimonials>
      <S.Inner>
        <Title isInverted>{title}</Title>
        <S.TestimonialsContainer>
          {testimonials.map((item, ind) => (
            <TestimonialItem key={ind} data={item} readMoreLink={readMoreLink} reducedText />
          ))}
        </S.TestimonialsContainer>
        {viewAllLink && (
          <OutboundLink url={viewAllLink.url} colour="#fff">
            {viewAllLink.label}
          </OutboundLink>
        )}
      </S.Inner>
    </S.Testimonials>
  )
}

Testimonials.propTypes = {
  title: PropTypes.string.isRequired,
  testimonials: PropTypes.arrayOf(PropTypes.any).isRequired,
  viewAllLink: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  readMoreLink: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
}.isRequired

Testimonials.defaultProps = {
  translations: {}
}

export default Testimonials
