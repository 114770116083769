import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const PageTemplate = ({ children, type }) => <S.Page type={type}>{children}</S.Page>

PageTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  type: PropTypes.string
}

PageTemplate.defaultProps = {
  type: null
}

export default PageTemplate
