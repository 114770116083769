/* eslint-disable react/prop-types */
import React from "react"
import * as S from "./style"

const RegisterInfoPopup = ({ onClick, content, closeButtonText }) => {
  return (
    <S.Container>
      <S.RegisterInfoPopup>
        <S.Text source={content} />
        <S.Button onClick={onClick}>{closeButtonText}</S.Button>
      </S.RegisterInfoPopup>
    </S.Container>
  )
}

export default RegisterInfoPopup
