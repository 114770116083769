import ReactMarkdown from "react-markdown"
import styled from "styled-components"

export const TextContainer = styled.div`
  flex: 1;

  margin: auto 0;
`

export const RadialPercentageContainer = styled.div`
  display: grid;
  grid-gap: 10%;
  grid-template-rows: 45% 45%;
  grid-template-columns: 45% 45%;

  width: 40%;
`

export const Commission = styled.div`
  display: flex;

  width: 100%;

  padding: 0 10px;

  background-color: ${props => props.bgColor};

  @media (max-width: 955px) {
    flex-direction: column;
    align-items: center;

    ${TextContainer} {
      width: 100%;

      text-align: center;
    }

    ${RadialPercentageContainer} {
      width: 60%;
    }
  }

  @media (max-width: 700px) {
    ${RadialPercentageContainer} {
      width: 80%;
    }
  }

  @media (max-width: 550px) {
    ${RadialPercentageContainer} {
      width: 100%;

      h3 {
        font-size: 70%;
      }
    }
  }
`

export const Markdown = styled(ReactMarkdown)`
  position: relative;
  z-index: 2;

  font-family: ${props => props.theme.fonts.primary};
  font-size: 1em;
  line-height: 24px;
`
