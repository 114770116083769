import styled, { css } from "styled-components"

import downIcon from "../../assets/icons/icon-chevrondown.svg"
import upIcon from "../../assets/icons/icon-chevronup.svg"
import { fadeInOut } from "../../styles/animation"

export const FaqItem = styled.div`
  color: ${props => props.theme.colours.black};
  font-family: ${props => props.theme.fonts.primary};

  cursor: pointer;

  padding: 30px 0;

  border-bottom: 1px solid #0000003b;

  animation: ${fadeInOut} 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;

  :first-child {
    border-top: 1px solid #0000003b;
  }
`

export const Question = styled.div`
  background-image: url(${props => (props.isOpen ? upIcon : downIcon)});
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 28px;

  p {
    font-weight: 700;
    font-size: 1em;
    line-height: 1.5em;
  }
`

export const Answer = styled.p`
  color: ${props => props.theme.colours.darkGrey};
  display: none;
  width: 95%;

  padding: 10px 0;

  font-size: 1em;
  line-height: 1.3em;

  ${props =>
    props.isOpen &&
    css`
      display: block;
    `};
`
