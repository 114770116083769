import { LazyLoadImage } from "react-lazy-load-image-component"
import ReactMarkdown from "react-markdown"
import styled, { css } from "styled-components"

import { smallConstrictedContainer, markdownStyling } from "../../styles/globalStyle"

export const BrandBanner = styled(LazyLoadImage)`
  width: 100%;
`

export const BrandContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;

  padding: 40px 0;

  @media (max-width: 768px) {
    padding: 20px 0;
  }
`

export const About = styled.div`
  ${smallConstrictedContainer};

  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const AboutInner = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    align-items: center;

    width: 80%;
  }

  @media (max-width: 550px) {
    width: 98%;
  }
`

const brandMarkdownStyling = css`
  ${markdownStyling};

  margin: 0;

  color: #fff;
  font-family: ${props => props.theme.fonts.openSans};

  border: none;
`

export const Description = styled(ReactMarkdown)`
  ${brandMarkdownStyling};

  display: flex;
  flex-direction: column;

  margin: 20px 0;

  color: ${props => props.theme.colours.black};
`

export const MarketsContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  a {
    text-decoration: none;
  }
`

export const MarketsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  width: 100%;
  max-width: 100%;
  gap: 12px 0;
`

export const Market = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    color: #000;
  }

  span {
    padding-top: 10px;
    font-size: 0.8em;
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  :hover {
    transform: scale(1.05);
    transition: transform 0.15s ease-in-out;
  }
`

export const FlagImage = styled(LazyLoadImage)`
  max-width: 35px;
  border-radius: 50%;
  box-shadow: 1px 1px 1px rgb(0, 0, 0, 0.3);
`

export const MarketTitle = styled.div`
  color: ${props => props.textColor};
  text-transform: uppercase;
  font-weight: bold;

  letter-spacing: 0.1em;
`

export const AbsoluteImage = styled(LazyLoadImage)`
  position: absolute;
  top: 20px;
  left: 50%;

  transform: translateX(-50%);

  width: 65%;
  height: auto;
`

export const Image = styled(LazyLoadImage)`
  width: 90%;

  @media (min-width: 600px) and (max-width: 900px) {
    width: 65%;
  }
`

export const FeatureStrip = styled.div`
  width: 100%;
  background: ${props => props.colour};
`

export const FeatureStripInner = styled.div`
  ${smallConstrictedContainer};

  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding: 10px 20px;
  height: 100%;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

export const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;

  width: 150px;
  height: 100%;

  p {
    margin-top: 10px;

    font-family: ${props => props.theme.fonts.openSans};
    font-size: 0.8em;
    line-height: normal;
    color: ${props => props.textColour};
    text-transform: capitalize;
    text-align: center;
  }

  :not(:last-child) {
    margin-right: 48px;
  }

  @media (max-width: 768px) {
    p {
      font-size: 0.7em;
    }
    :not(:last-child) {
      margin-right: 20px;
    }
  }
`

export const FeatureIcon = styled.div`
  height: 35px;
  width: 35px;

  img {
    width: 100%;
  }
`

export const ButtonContainer = styled.div`
  max-width: 25%;
  margin-left: 40px;
  padding-top: 30px;
  width: 100%;

  a {
    width: 100%;
    max-width: 250px;
    font-size: 1em;
    height: 56px;
    line-height: 56px;
    margin: 0;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin: 0;

    max-width: 100%;
  }
`

export const CtaButton = styled.button`
  padding: 18px;
  margin: 25px 0;

  width: 100%;
  max-width: 250px;

  background: ${props => props.colour || props.theme.colours.main};
  color: #fff;
  text-transform: uppercase;

  font-family: ${props => props.theme.fonts.primary};
  font-size: 1em;
  font-weight: 600;

  border: none;

  cursor: pointer;
`
