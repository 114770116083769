/* eslint-disable max-len */
import * as PropTypes from "prop-types"
import React from "react"
import { useTheme } from "styled-components"

import About from "../../components/About"
import Brands from "../../components/Brands"
import Commission from "../../components/Commission"
import Contact from "../../components/Contact"
import Hero from "../../components/Hero"
import News from "../../components/News"
import PageTemplate from "../PageTemplate"
import Testimonials from "../../components/Testimonials"

const HomePage = ({ homePage, translations }) => {
  const {
    bannerSection,
    aboutTitle,
    aboutText,
    brandsList,
    commissionTitle,
    commissionText,
    value1,
    value2,
    value3,
    value4,
    value1Text,
    value2Text,
    value3Text,
    value4Text,
    news,
    testimonials
  } = homePage

  const theme = useTheme()

  const aboutData = {
    config: {
      bgTitle: aboutTitle
    },
    content: {
      title: aboutTitle,
      text: aboutText,
      ctaText: translations.register
    }
  }

  const brandData = {
    config: {
      bgColor: theme.homepageBrands.bgColor,
      bgTitle: translations.brands,
      bgTitleColor: "rgba(255, 255, 255, 0.2)",
      outboundLink: translations.brands.toLowerCase(),
      outboundText: translations.allBrandsLink,
      outboundLinkColor: "#fff"
    },
    content: {
      brands: brandsList
    }
  }

  const commissionData = {
    config: {
      bgColor: "#fff",
      bgTitle: commissionTitle
    },
    content: {
      title: commissionTitle,
      text: commissionText,
      commissions: [
        { id: 0, title: value1Text, value: value1 },
        { id: 1, title: value2Text, value: value2 },
        { id: 2, title: value3Text, value: value3 },
        { id: 3, title: value4Text, value: value4 }
      ]
    }
  }

  const newsData = {
    config: {
      padding: "large",
      bgTitle: translations.news,
      outboundLink: `/${translations.news.toLowerCase()}`,
      outboundText: translations.allNewsLink,
      outboundLinkColor: "#000"
    },
    content: {
      title: translations.news,
      articles: news
    }
  }

  const testimonialsData = {
    content: {
      title: translations.testimonials,
      items: testimonials
    }
  }

  const contactData = {
    config: {
      bgColor: "#EDEDED",
      padding: "large"
    },
    translations
  }

  return (
    <PageTemplate>
      <Hero mobileVideo={bannerSection[0].mobileMedia} desktopVideo={bannerSection[0].media} />
      <Brands
        title={brandData.config.bgTitle}
        brands={brandData.content.brands}
        viewAllLink={{
          label: translations.allBrandsLink,
          url: translations.brands.toLowerCase()
        }}
      />
      <About data={aboutData} />
      <News
        title={newsData.config.bgTitle}
        news={newsData.content.articles.slice(0, 4)}
        viewAllLink={{
          label: translations.allNewsLink,
          url: translations.news.toLowerCase()
        }}
      />
      <Commission data={commissionData} />
      {testimonials.length > 0 && (
        <Testimonials
          title={testimonialsData.content.title}
          testimonials={testimonialsData.content.items.slice(0, 3)}
          viewAllLink={{
            label: translations.allTestimonialsLink,
            url: translations.testimonials.toLowerCase()
          }}
          readMoreLink={{
            label: translations.readMore,
            url: translations.testimonials.toLowerCase()
          }}
        />
      )}
      <Contact data={contactData} />
    </PageTemplate>
  )
}

HomePage.propTypes = {
  homePage: PropTypes.objectOf(PropTypes.any).isRequired,
  translations: PropTypes.objectOf(PropTypes.any).isRequired
}

export default HomePage
