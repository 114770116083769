import PropTypes from "prop-types"
import React from "react"

import { setQualityContentfulImage, createBrandString } from "../../helpers"
import * as S from "./style"

const createLink = slug => `/news/${slug}`

const NewsGridItem = ({ data: { newsTitle, slug, newsThumbnail, categories }, size }) => (
  <S.NewsGridItem size={size} to={createLink(slug)}>
    <S.Image alt="Article" src={setQualityContentfulImage(newsThumbnail, 75)} />
    <div>
      <p>{createBrandString(categories)}</p>
      <h3>{newsTitle}</h3>
    </div>
  </S.NewsGridItem>
)

NewsGridItem.propTypes = {
  data: PropTypes.shape({
    newsDate: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.any),
    newsTitle: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    newsThumbnail: PropTypes.string,
    newsImage: PropTypes.string
  }).isRequired,
  size: PropTypes.string
}

NewsGridItem.defaultProps = {
  size: ""
}

export default NewsGridItem
