/* eslint-disable react/prop-types */
import * as PropTypes from "prop-types"
import React, { useState } from "react"

import Title from "../../components/Title"
import { useScrollTo } from "../../helpers"
import PageTemplate from "../PageTemplate"
import * as S from "./style"
import RegisterInfoPopup from "../../components/RegisterInfoPopup"

const Registration = ({ translations, registration, url }) => {
  useScrollTo()

  const [isPopupOpen, setIsPopupOpen] = useState(true)

  return (
    <PageTemplate>
      <S.Registration>
        <Title>{translations.affiliatesRegistration}</Title>
        {isPopupOpen && (
          <RegisterInfoPopup
            onClick={() => setIsPopupOpen(false)}
            content={registration[0].popupContent}
            closeButtonText={registration[0].popupCloseButtonText}
          />
        )}
        <S.Iframe src={url} />
      </S.Registration>
    </PageTemplate>
  )
}

Registration.propTypes = {
  translations: PropTypes.objectOf(PropTypes.any),
  url: PropTypes.string
}
Registration.defaultProps = {
  translations: {},
  url: null
}

export default Registration
