import styled from "styled-components"

import { constrictedContainer } from "../../styles/globalStyle"

export const NotFound = styled.div`
  ${constrictedContainer};

  flex: 1;

  padding-top: 150px;

  font-family: ${props => props.theme.fonts.primary};

  min-height: ${props => (props.isMobile ? "100vh" : "")};

  h1 {
    font-size: 2em;
  }
`
