import PropTypes from "prop-types"
import React, { useState } from "react"
import { useCookies } from "react-cookie"

import { encode } from "../../helpers"
import { SUCCESS, ERROR, BOT_FIELD } from "../../helpers/constants"
import Button from "../Button"
import * as S from "./style"

const RaceState = {
  UNBEGUN: "UNBEGUN",
  ACTIVE: "ACTIVE",
  EXPIRED: "EXPIRED"
}

const placings = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
  "13th",
  "14th",
  "15th",
  "16th",
  "17th",
  "18th",
  "19th",
  "20th"
]

const formData = {
  name: "race",
  inputs: {
    nickname: {
      id: "nickname",
      text: "Nickname"
    },
    affiliateid: {
      id: "affiliateid",
      text: "Affiliate IDs (separate by comma)"
    },
    email: {
      id: "email",
      text: "Email"
    }
  }
}

const initialFields = {
  nickname: null,
  affiliateid: null,
  email: null
}

const RaceForm = ({ slug, title, /* startDate, */ endDate, leaderboards, translations }) => {
  const [state, setState] = useState(RaceState.ACTIVE)
  const [fields, setFields] = useState(initialFields)
  const [changedFields, setChangedFields] = useState(initialFields)
  const [validatedFields, setValidatedFields] = useState(initialFields)
  const [alertType, setAlertType] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const [cookies, setCookie] = useCookies(["affiliates-cookies"])

  const { /* raceFormNotStarted, raceFormEnded, raceFormEndsOn, */ raceSubmitMessage, raceUpdated } = translations

  const alerts = {
    success: raceSubmitMessage,
    error: translations.errorMessageAlert
  }

  // const startDateObject = new Date(startDate)
  // const endDateObject = new Date(endDate)

  // React.useEffect(() => {
  //   const currentDate = Date.now()

  //   if (currentDate >= startDateObject && (!endDate ||  currentDate < endDateObject)) {
  //     setState(RaceState.ACTIVE)
  //   } else if (endDate && currentDate >= endDateObject) {
  //     setState(RaceState.EXPIRED)
  //   }
  // }, [endDate, endDateObject, startDate, startDateObject])

  const endDateObject = new Date(endDate)

  React.useEffect(() => {
    const currentDate = Date.now()

    if (currentDate > endDateObject) {
      setState(RaceState.EXPIRED)
    } else {
      setState(RaceState.ACTIVE)
    }
  }, [state, endDateObject])

  const hideAlert = () => setTimeout(() => setAlertType(null), 4000)

  const sendData = () => {
    const url = "/news/"
    const init = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "race",
        ...fields,
        slug
      })
    }

    fetch(url, init)
      .then(() => {
        setAlertType(SUCCESS)

        setCookie(`race-${slug}`, true)
        setIsSubmitted(true)
      })
      .catch(() => setAlertType(ERROR))
  }

  const handleChange = ({ target: { name, value } }) => {
    let isValid

    switch (name) {
      default: {
        isValid = value.trim().length > 0
        setFields({ ...fields, ...{ [name]: value } })
        break
      }
      case formData.inputs.email.id: {
        isValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        setFields({ ...fields, ...{ [name]: value } })
        break
      }
      case formData.inputs.affiliateid.id: {
        const values = value.replace(/ /g, "").split(",")
        isValid = values.every(val => val.match(/^([0-9]{6})+$/))
        setFields({ ...fields, ...{ [name]: values.join(", ") } })
        break
      }
      case formData.inputs.nickname.id: {
        isValid = value.match(/^.{3,20}$/)
        setFields({ ...fields, ...{ [name]: value } })
        break
      }
    }

    if (!changedFields[name]) {
      setChangedFields({ ...changedFields, ...{ [name]: true } })
    }

    setValidatedFields({ ...validatedFields, ...{ [name]: isValid } })
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (state !== RaceState.ACTIVE) {
      return
    }

    sendData()
    hideAlert()

    document.activeElement.blur()
  }

  const hasError = name => changedFields[name] && !validatedFields[name]

  const isDisabled = !Object.values(validatedFields).every(field => field)

  const hasAlreadyRegistered = cookies[`race-${slug}`]

  return (
    <S.RaceContainer>
      <S.Race>
        <S.Title>{title}</S.Title>
        {(hasAlreadyRegistered || isSubmitted) && <S.CenteredText>You&apos;ve already registered for this race</S.CenteredText>}
        {/* {state === RaceState.ACTIVE && endDate (
          <S.CenteredText>
            {`${raceFormEndsOn} ${endDateObject.toString()}`}
          </S.CenteredText>
        )}
        {state !== RaceState.ACTIVE && (
          <S.Overlay>
            {state === RaceState.UNBEGUN && (
              <>
                <span>{raceFormNotStarted}</span>
                <span>{startDateObject.toString()}</span>
              </>
            )}
            {state === RaceState.EXPIRED && (
              <>
                <span>{raceFormEnded}</span>
                <span>{endDateObject.toString()}</span>
              </>
            )}
          </S.Overlay>
        )} */}

        {alertType && <S.Alert type={alertType}>{alerts[alertType]}</S.Alert>}

        {!hasAlreadyRegistered && !isSubmitted && state === RaceState.ACTIVE && (
          <S.Form
            name={formData.name}
            form-name={formData.name}
            method="POST"
            data-netlify
            netlify-honeypot={BOT_FIELD}
            data-netlify-honeypot={BOT_FIELD}
            onChange={handleChange}
            onSubmit={handleSubmit}
          >
            <S.HiddenInput name="slug" type="hidden" value={slug} />
            <S.TextInput
              name={formData.inputs.nickname.id}
              aria-label={formData.inputs.nickname.text}
              placeholder={formData.inputs.nickname.text}
              error={hasError(formData.inputs.nickname.id)}
              minLength={3}
              maxLength={12}
            />
            <S.TextInput
              name={formData.inputs.affiliateid.id}
              aria-label={formData.inputs.affiliateid.text}
              placeholder={formData.inputs.affiliateid.text}
              error={hasError(formData.inputs.affiliateid.id)}
              minLength={6}
            />
            <S.EmailInput
              name={formData.inputs.email.id}
              aria-label={formData.inputs.email.text}
              placeholder={formData.inputs.email.text}
              error={hasError(formData.inputs.email.id)}
            />

            <S.HiddenInput name={BOT_FIELD} />

            <Button size="medium" type="submit" isDisabled={isDisabled}>
              Submit
            </Button>
          </S.Form>
        )}
      </S.Race>
      {state !== RaceState.UNBEGIN && leaderboards.filter(({ leaderboard }) => leaderboard && leaderboard.length > 0).length > 0 && (
        <S.LeaderboardContainer>
          {leaderboards.length > 0 &&
            leaderboards.map(
              ({ leaderboardTitle, leaderboard }, i) =>
                leaderboard && (
                  <S.Leaderboard key={i}>
                    <S.Title>{leaderboardTitle || `Leaderboard ${i + 1}`}</S.Title>
                    {state !== RaceState.ACTIVE && <S.UpdatedOn>{raceUpdated}</S.UpdatedOn>}
                    <S.LeaderboardEntries>
                      {leaderboard.map((username, index) => (
                        <S.LeaderboardEntry key={index}>
                          {index === 0 && (
                            <>
                              <S.FirstPlaceSquare position="left" />
                              <S.FirstPlaceSquare />
                            </>
                          )}
                          <S.Placing>{placings[index]}</S.Placing>
                          <S.Nickname>{username}</S.Nickname>
                        </S.LeaderboardEntry>
                      ))}
                    </S.LeaderboardEntries>
                  </S.Leaderboard>
                )
            )}
        </S.LeaderboardContainer>
      )}
    </S.RaceContainer>
  )
}

RaceForm.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  leaderboards: PropTypes.arrayOf(PropTypes.string),
  translations: PropTypes.objectOf(PropTypes.any).isRequired
}

RaceForm.defaultProps = {
  leaderboards: []
}

export default RaceForm
