import leovegasAccountImage from "../assets/leovegas/faq/account.svg"
import leovegasfriendsImage from "../assets/leovegas/faq/friends.svg"
import leovegasPaymentImage from "../assets/leovegas/faq/payment.svg"
import leovegasSearchIcon from "../assets/leovegas/icon-search.svg"
import sailfishAccountImg from "../assets/sailfish/faq/account.svg"
import sailfishFriendsImg from "../assets/sailfish/faq/friends.svg"
import sailfishPaymentImg from "../assets/sailfish/faq/payment.svg"
import sailfishTestimonialHeader from "../assets/sailfish/fish.png"
import sailfishSearchIcon from "../assets/sailfish/icon-search.svg"

const palette = {
  gold: "#BDA770",
  black: "#000",
  white: "#fff"
}

export const themes = {
  leovegas: {
    colours: {
      main: palette.gold,
      black: "#000",
      darkGrey: "#3b3b3b",
      white: "#fff",
      title: "#fff"
    },
    fonts: {
      primary: `'Wix Madefor Display', sans-serif`
    },
    fontsUrl: "https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400;500;600;700;800&display=swap",
    button: {
      medium: {
        fontSize: "0.75"
      },
      large: {
        fontSize: "1"
      }
    },
    padding: {
      squashedPadding: "15px"
    },
    sizes: {
      navigation: {
        mobile: 846
      },
      header: {
        mobile: 846
      },
      footer: {
        mobile: 846
      },
      about: {
        mobile: 846
      },
      faq: {
        small: 650,
        medium: 750,
        large: 850
      },
      aboutCollapse: 800
    },
    footer: {
      bgColor: palette.black
    },
    homepageBrands: {
      bgColor: palette.black
    },
    registerContainer: {
      bgColor: palette.black
    },
    news: {
      linkPreview: palette.gold,
      previewInfoBg: palette.black
    },
    cookieConsent: {
      disclaimer: {
        fontSize: "1em",
        colour: "#000",
        link: {
          colour: "#008c70"
        }
      },
      bgColour: "#fff",
      borderColour: "#e0e0e0",
      cookieIcon: {
        bgColour: "#f1f0ec",
        colour: "#000"
      },
      toggles: {
        track: {
          offColour: "#b6b6b6",
          onColour: "#e8e0d0"
        },
        pin: {
          offColour: "#e2e2e2",
          onColour: palette.gold
        },
        labelColour: "#000",
        descriptionColour: "#c0c0c0"
      },
      buttons: {
        fontSize: "1em",
        textTransform: "uppercase",
        allowAll: {
          colour: "#000",
          bgColour: palette.gold,
          borderRadius: "32px",
          padding: "0 32px",
          maxHeight: "48px",
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 5px -3px",
          width: "max-content",
          hover: {
            bgColour: "#c6b38c"
          }
        },
        manage: {
          colour: palette.gold,
          bgColour: "transparent",
          hover: {
            bgColour: "#c8b69061"
          },
          borderRadius: "32px",
          padding: "0 14px",
          maxHeight: "48px"
        },
        confirm: {
          colour: "#000",
          bgColour: palette.gold,
          borderRadius: "32px",
          padding: "0 32px",
          maxHeight: "48px",
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 5px -3px",
          hover: {
            bgColour: "#c6b38c"
          }
        }
      }
    },
    faq: {
      icons: {
        searchIcon: leovegasSearchIcon,
        accountImage: leovegasAccountImage,
        friendsImage: leovegasfriendsImage,
        paymentImage: leovegasPaymentImage
      }
    },
    testimonials: {
      bgPageColour: "#fff",
      headerBgColour: "#fff",
      headerTextColour: "#fff",
      nameColour: "#000",
      nameBackground: palette.gold,
      textColour: "#094664",
      urlColour: "#1480B8"
    }
  },
  sailfish: {
    colours: {
      main: "#2796cb",
      black: "#012c40",
      white: "#fff",
      title: "#fff",
      yellow: "#ffc72a"
    },
    fonts: {
      primary: '"Alumni Sans", serif',
      secondary: '"Open Sans", serif'
    },
    fontsUrl:
      "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap&family=Alumni+Sans:wght@300;400;500;600;700&display=swap",
    padding: {
      squashedPadding: "15px"
    },
    button: {
      medium: {
        fontSize: "1.1"
      },
      large: {
        fontSize: "1.5"
      }
    },
    sizes: {
      navigation: {
        mobile: 846
      },
      header: {
        mobile: 846
      },
      footer: {
        mobile: 846
      },
      about: {
        mobile: 846
      },
      faq: {
        small: 650,
        medium: 750,
        large: 850
      },
      aboutCollapse: 800
    },
    footer: {
      bgColor: "#012c40"
    },
    homepageBrands: {
      bgColor: "#012c40"
    },
    registerContainer: {
      bgColor: "#012c40"
    },
    news: {
      linkPreview: "#ffc72a",
      previewInfoBg: "#012c40"
    },
    cookieConsent: {
      link: "#2796cb"
    },
    faq: {
      icons: {
        searchIcon: sailfishSearchIcon,
        accountImage: sailfishAccountImg,
        friendsImage: sailfishFriendsImg,
        paymentImage: sailfishPaymentImg
      }
    },
    testimonials: {
      bgPageColour: "#f2f2f2",
      headerBgImage: sailfishTestimonialHeader,
      headerBgColour: "#2796cb",
      headerTextColour: "#fff",
      nameColour: "#ffc72a",
      textColour: "#094664",
      urlColour: "#1480B8"
    }
  }
}
