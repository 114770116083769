import React from "react"
import PropTypes from "prop-types"

import * as S from "./style"

const BrandTileSmall = ({ logoUrl, href }) => {
  return (
    <S.BrandTileSmall to={href}>
      <img src={logoUrl} alt="" />
    </S.BrandTileSmall>
  )
}

export default BrandTileSmall

BrandTileSmall.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
}
