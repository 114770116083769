import { LazyLoadImage } from "react-lazy-load-image-component"
import { HashLink } from "react-router-hash-link"
import styled, { css } from "styled-components"

import closeIcon from "../../assets/icons/icon-close-black.svg"
import globeIcon from "../../assets/icons/icon-globe.svg"
import menuIcon from "../../assets/icons/icon-mobilemenu.svg"
import { constrictedContainer } from "../../styles/globalStyle"

const NavLink = styled(HashLink)`
  text-transform: uppercase;

  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 600;
  font-size: 0.78em;
  text-decoration: none;

  cursor: pointer;
`

export const WebLink = styled(NavLink)`
  box-sizing: border-box;

  height: 20px;

  margin-right: 22px;

  color: ${props => props.theme.colours.black};
  line-height: 20px;

  :hover {
    color: ${props => props.theme.colours.main};
  }

  ${props =>
    props.$isSelected &&
    css`
      color: ${props.theme.colours.main};
    `}
`

export const FlexSpacer = styled.div`
  flex: 1;
`

export const MenuButton = styled.div`
  position: relative;
  width: 30px;
  height: 30px;

  background-image: url(${globeIcon});
  background-color: transparent;
  background-position: 50%;
  background-size: 100%;

  border: none;
  z-index: 99;

  cursor: pointer;
  margin-left: 15px;

  :hover {
    color: ${props => props.theme.colours.main};
  }

  ${props =>
    props.isOpen &&
    css`
      background-image: url(${closeIcon});
    `}

  @media (max-width: ${props => props.theme.sizes.navigation.mobile}px) {
    ${props =>
      !props.isOpen &&
      css`
        background-image: url(${menuIcon});
      `}
  }
`

export const NavBar = styled.nav`
  position: fixed;
  top: 0;

  z-index: 10;

  display: flex;
  justify-content: center;

  width: 100%;
  height: 60px;

  background-color: #fff;

  box-shadow: 0 2px 5px rgba(1, 1, 1, 0.25);
`

export const NavBarInner = styled.div`
  ${constrictedContainer}

  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Logo = styled(LazyLoadImage)`
  height: 40px;
`
