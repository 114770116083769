import styled from "styled-components"

export const Brands = styled.div`
  display: flex;
  justify-content: center;
  background: url("/assets/images/seamless-dark.jpg") #000;
  background-attachment: fixed;
  padding: 64px 0;
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  padding: 0 24px;
`

export const Title = styled.h2`
  font-size: 30px;
  line-height: 36px;
  color: #fff;
`

export const BrandContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
  gap: 12px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(9, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
`
