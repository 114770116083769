import ReactMarkdown from "react-markdown"
import styled, { css } from "styled-components"

import { fadeInOut } from "../../styles/animation"

export const Contact = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  background-color: ${props => props.bgColor};

  h3 {
    margin-bottom: 48px;

    font-size: 1.875em;
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 700;
    text-transform: uppercase;
  }
`

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(7, min-content);
  gap: 12px;

  min-width: 700px;

  @media (max-width: 768px) {
    min-width: 100%;
  }

  ${props =>
    props.isHidden &&
    css`
      visibility: hidden;
    `}

  button {
    grid-column: span 2 / span 2;
  }
`

const errorColour = "#e61a1a"

const inputSharedStyling = css`
  box-sizing: border-box;

  border: none;

  color: #000;

  border-radius: 8px;
  background-color: #fff;
  padding: 0 10px;

  ::placeholder {
    color: #000;
    text-transform: capitalize;
  }

  :focus {
    outline: none;
    border-color: #000;

    color: ${props => props.theme.colours.black};
  }

  ${props =>
    props.error &&
    css`
      border-color: ${errorColour};

      color: ${errorColour};

      ::placeholder {
        color: ${errorColour};
      }

      :focus {
        border-color: ${errorColour};

        color: ${errorColour};
      }
    `}
`

export const TextNameInput = styled.input.attrs({
  type: "text"
})`
  ${inputSharedStyling};

  display: inline-block;

  width: 100%;
  height: 40px;

  @media (max-width: 550px) {
    display: block;

    width: 100%;
  }

  grid-row-start: 1;
  grid-column: span 2 / span 2;

  @media (min-width: 768px) {
    grid-column: span 1 / span 1;
    grid-row-start: 1;
    grid-column-start: 1;
  }
`

export const TextSurnameInput = styled.input.attrs({
  type: "text"
})`
  ${inputSharedStyling};

  display: inline-block;

  width: 100%;
  height: 40px;

  @media (max-width: 550px) {
    display: block;

    width: 100%;
  }

  grid-row-start: 2;
  grid-column: span 2 / span 2;

  @media (min-width: 768px) {
    grid-column: span 1 / span 1;
    grid-row-start: 1;
    grid-column-start: 2;
  }
`

export const EmailInput = styled.input.attrs({
  type: "email"
})`
  ${inputSharedStyling};

  width: 100%;
  height: 40px;

  grid-column: span 2 / span 2;
`

export const HiddenInput = styled.input`
  display: none;
`

export const TextArea = styled.textarea`
  ${inputSharedStyling};

  min-width: 100%;
  min-height: 120px;
  max-height: 200px;

  padding: 10px;

  grid-column: span 2 / span 2;

  resize: vertical;
`
export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  span {
    font-family: ${props => props.theme.fonts.primary};
  }

  grid-column: span 2 / span 2;

  @media (max-width: 768px) {
    p {
      margin-left: 10px;
    }
  }
`
export const CheckBox = styled.input.attrs(() => ({ type: "checkbox" }))`
  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`

export const AcceptTermsLink = styled(ReactMarkdown)`
  display: inline-block;

  a {
    color: #000;

    :visited {
      color: ${props => props.theme.colours.main};
    }
  }
`

export const Alert = styled.div`
  position: absolute;
  top: 40px;

  padding: 0.5rem 1.25rem;

  border: 1px solid transparent;
  border-radius: 0.25rem;

  opacity: 0;

  animation: 1s ${fadeInOut} ease-out 0.5s forwards, 1s ${fadeInOut} ease-out 2.5s forwards reverse;

  ${props =>
    props.type === "success" &&
    css`
      background-color: #d4edda;

      border-color: #c3e6cb;

      color: #155724;
    `}

  ${props =>
    props.type === "error" &&
    css`
      background-color: #f8d7da;

      border-color: #f5c6cb;

      color: #721c24;
    `}
`
