import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const Title = ({ children, isInverted }) => <S.Title isInverted={isInverted}>{children}</S.Title>

Title.propTypes = {
  children: PropTypes.string.isRequired,
  isInverted: PropTypes.bool
}

Title.defaultProps = {
  isInverted: false
}

export default Title
