import React from "react"

import { useScreenSize } from "../../utils/hooks"
import * as S from "./style"

const NotFound = () => {
  const screenSize = useScreenSize()

  return (
    <S.NotFound isMobile={screenSize.width.lessThan(768)}>
      <h1>Page not found</h1>
    </S.NotFound>
  )
}

export default NotFound
