import { css, createGlobalStyle } from "styled-components"

const resetCss = css`
  /* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
    */

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    //font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    play: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html,
  body,
  #root {
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }

  * {
    font-family: ${props => props.theme.fonts.primary};
    box-sizing: border-box;
  }
`

const GlobalStyle = createGlobalStyle`
    ${resetCss};

    ${props =>
      props.$lockBodyScroll &&
      css`
        body {
          overflow-y: hidden !important;
        }
      `}
`

export const constrictedContainer = css`
  box-sizing: border-box;

  width: 100%;
  max-width: 1280px;

  margin: 0 auto;
  padding: 0 ${props => props.theme.padding.squashedPadding};
`

export const smallConstrictedContainer = css`
  ${constrictedContainer};

  max-width: 1100px;
`

export const markdownStyling = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 20px;

    font-weight: 700;
  }

  h1 {
    padding-bottom: 5px;

    font-size: 2.4em;
    border-bottom: 1px solid #e9e9e9;
  }

  h2 {
    padding-bottom: 5px;

    font-size: 2em;
    border-bottom: 1px solid #e9e9e9;
  }

  h3 {
    font-size: 1.6em;
  }

  h4 {
    font-size: 1.3em;
  }

  h5 {
    font-size: 1.2em;
  }

  h6 {
    font-size: 1.1em;
  }

  p {
    margin-bottom: 20px;

    font-size: 1em;
    line-height: 1.5em;
  }

  blockquote {
    padding: 6px 0 6px 12px;
    margin: 20px 0;

    border-left: 3px solid ${props => props.theme.colours.main};

    font-style: italic;

    p {
      margin: 0;
    }
  }

  a {
    color: ${props => props.theme.colours.main};
  }

  ul,
  ol {
    margin: 0 0 20px 40px;
    ul,
    ol {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: upper-roman;
  }

  li {
    margin-bottom: 5px;
    line-height: 1.5em;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  pre {
    padding: 15px;
    margin-bottom: 15px;

    background-color: #e9e9e9;

    border-radius: 2px;
    border: 1px solid #e3e3e3;

    font-family: ${props => props.theme.fonts.openSans};
    font-size: 0.8em;
    line-height: 1.5em;
  }

  table {
    margin-bottom: 15px;
  }

  tr,
  th,
  td {
    padding: 7px;

    border: 1px solid #e9e9e9;
  }

  tr:nth-child(2n + 2) {
    background-color: #f6f6f6;
  }

  th {
    background-color: #e3e3e3;
  }
`

export default GlobalStyle
