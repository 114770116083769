import styled from "styled-components"

export const Hero = styled.div`
  position: relative;

  min-height: 400px;
  max-height: 400px;

  margin-top: 60px;

  overflow: hidden;

  @media (min-width: 768px) {
    min-height: 600px;
    max-height: 600px;
  }
`

export const HeroVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;

  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;

  transform: translate(-50%, -50%);

  display: ${({ isMobile }) => (!isMobile ? "none" : "block")};

  @media (min-width: 768px) {
    display: ${({ isMobile }) => (isMobile ? "none" : "block")};
  }
`
