import TagManager from "react-gtm-module"

const { REACT_APP_GTM_ID: gtmId } = process.env

export const initialize = () => {
  TagManager.initialize({
    gtmId,
    dataLayer: []
  })
}

export const pushToDataLayer = ({ action, label }) => {
  if (!action) return

  const layerData = {
    dataLayer: {
      event: "eventClick",
      category: "click",
      action
    }
  }

  if (label) {
    layerData.dataLayer.label = label
  }

  TagManager.dataLayer(layerData)
}

export function pushConsentToDataLayer() {
  // eslint-disable-next-line prefer-rest-params
  dataLayer.push(arguments)
}
