import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const NewsGridItem = styled(Link)`
  display: flex;
  flex-direction: row;

  gap: 15px;
  row-gap: 5px;

  line-height: 1.5em;

  width: 100%;

  text-decoration: none;
  color: #000;

  font-size: 1.2rem;

  @media (min-width: 500px) {
    flex-direction: column;

    width: 225px;
  }

  @media (min-width: 700px) {
    width: 200px;

    h3 {
      font-size: 0.9em;
    }
  }

  @media (min-width: 900px) {
    width: 275px;
  }

  p {
    font-size: 0.7em;
  }
`

export const Image = styled(LazyLoadImage)`
  width: 125px;
  height: 125px;

  @media (min-width: 500px) {
    width: 100%;
    height: auto;
  }
`
