import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const HomeSection = ({ id, config: { bgColor, outboundLink, outboundText, outboundLinkColor }, children, useBg }) => (
  <S.HomeSection id={id} bgColor={bgColor} useBg={useBg}>
    <S.ChildrenContainer>{children}</S.ChildrenContainer>

    {outboundLink && (
      <S.OutboundLink to={outboundLink} outboundlinkcolor={outboundLinkColor}>
        {outboundText}
      </S.OutboundLink>
    )}
  </S.HomeSection>
)

HomeSection.propTypes = {
  id: PropTypes.string,
  config: PropTypes.shape({
    bgColor: PropTypes.string,
    bgTitle: PropTypes.string,
    bgTitleColor: PropTypes.string,
    outboundLink: PropTypes.string,
    outboundText: PropTypes.string,
    outboundLinkColor: PropTypes.string
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  useBg: PropTypes.bool
}

HomeSection.defaultProps = {
  config: {},
  id: "",
  useBg: false
}

export default HomeSection
