import { LazyLoadImage } from "react-lazy-load-image-component"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import clockIcon from "../../assets/icons/icon-clock.svg"
import { constrictedContainer, markdownStyling } from "../../styles/globalStyle"

export const Banner = styled.div`
  max-height: 720px;

  @media (min-width: 600px) {
    max-width: 1100px;
    margin: 32px auto;
    padding: 0 15px;
  }
`

export const BannerTitle = styled.h1`
  font-size: 2em;
  font-weight: 700;
  color: #000;

  margin: 20px 0;
`

export const BannerInner = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;

    @media (min-width: 600px) {
      border-radius: 8px;
    }

    @media (min-width: 1920px) {
      width: 1920px;
      height: 720px;
    }
  }
`

export const WebTitle = styled.h1`
  position: relative;
  z-index: 2;

  width: 650px;

  padding: 50px 50px 50px 0;

  font-size: 3.2em;
  font-weight: 700;
  color: #fff;
`

export const NewsArticle = styled.article`
  /* margin-top: 120px; */
  margin-top: 60px;

  font-family: ${props => props.theme.fonts.primary};

  @media (max-width: 1233px) {
    ${WebTitle} {
      width: 425px;

      font-size: 2.1em;
    }
  }

  @media (max-width: 855px) {
    margin-top: 60px;

    ${Banner} {
      flex-direction: column;
      align-items: flex-start;

      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
`

export const ContentContainer = styled.div`
  /* ${constrictedContainer}; */

  box-sizing: border-box;

  width: 100%;
  max-width: 900px;

  margin: 0 auto;

  padding: 15px;

  font-size: 0.9em;

  @media (min-width: 500px) {
    font-size: 0.95em;
  }

  @media (min-width: 700px) {
    font-size: 1em;
  }
`

export const Date = styled.p`
  height: 20px;

  margin-bottom: 20px;

  background-image: url(${clockIcon});
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 0% 3px;

  color: ${props => props.theme.colours.black};
  font-size: 1.1em;
  font-weight: 700;
  text-indent: 20px;
`

export const Related = styled.div`
  display: flex;
  flex-direction: column;

  border-top: 1px solid rgb(233, 233, 233);
  padding: 25px 0;
`

export const RelatedHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 30px;

  font-weight: 700;

  h3 {
    font-size: 2em;
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    font-size: 1.1em;
    color: ${props => props.theme.colours.black};

    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const RelatedArticles = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  margin-bottom: 30px;

  a:not(:last-child) {
    margin-right: 20px;
  }

  @media (max-width: 1310px) {
    a:nth-child(4) {
      display: none;
    }

    a:nth-child(3) {
      margin-right: 0;
    }
  }

  @media (max-width: 982px) {
    flex-direction: column;

    a {
      width: 100%;
    }

    a:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`
export const ArticleContent = styled.div`
  padding-bottom: 60px;
`

export const ContactText = styled.p`
  font-weight: 700;
  a {
    color: ${props => props.theme.colours.main};

    :visited {
      color: ${props => props.theme.colours.main};
    }
  }
`

export const Markdown = styled(ReactMarkdown)`
  ${markdownStyling}

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.3em;
  }

  h4 {
    font-size: 1.1em;
  }
  h2,
  h3,
  h4 {
    margin-top: 35px;
  }
`

export const ImageRenderer = styled(LazyLoadImage)`
  max-width: 100%;

  ${({ isMobile }) => isMobile && "width: 100%"};
`

export const ImageRendererNoGap = styled(LazyLoadImage)`
  display: block;
  max-width: 100%;

  ${({ isMobile }) => isMobile && "width: 100%"};
`

export const DownloadableFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  margin: 50px 0;
`

export const DownloadableFiles = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  gap: 20px;

  width: fit-content;
  cursor: pointer;
`
