import PropTypes from "prop-types"
import React from "react"

import DownloadableItem from "../../components/DownloadableItem"
import Title from "../../components/Title"
import PageTemplate from "../PageTemplate"
import * as S from "./style"

const DownloadsPage = ({ downloads, translations }) => {
  const logos = downloads.map((item, i) => {
    const { downloadableLogo, brandName, brandLogo } = item
    const { fileUrl, name } = downloadableLogo

    return <DownloadableItem key={i} brandLogo={brandLogo} brandName={brandName} url={fileUrl} fileName={name} />
  })

  return (
    <PageTemplate>
      <S.DownloadableLogoContainer>
        <Title>{translations.downloadsHeader}</Title>
        <S.LogosInner>{logos}</S.LogosInner>
      </S.DownloadableLogoContainer>
    </PageTemplate>
  )
}

DownloadsPage.propTypes = {
  downloads: PropTypes.arrayOf(PropTypes.any).isRequired,
  translations: PropTypes.objectOf(PropTypes.any)
}

DownloadsPage.defaultProps = {
  translations: {}
}

export default DownloadsPage
