import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const OutboundLink = ({ children, url, colour }) => (
  <S.OutboundLink to={url} outboundlinkcolor={colour}>
    {children}
  </S.OutboundLink>
)

OutboundLink.propTypes = {
  children: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  colour: PropTypes.string
}

OutboundLink.defaultProps = {
  colour: "#000"
}

export default OutboundLink
