import styled from "styled-components"

import { constrictedContainer } from "../../styles/globalStyle"

export const Registration = styled.div`
  ${constrictedContainer};

  margin: 100px auto 60px;

  flex: 1;

  font-family: ${props => props.theme.fonts.primary};
`

export const Iframe = styled.iframe`
  width: 100%;
  height: 2100px;
`
