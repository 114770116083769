import styled from "styled-components"

import { constrictedContainer } from "../../styles/globalStyle"

export const FaqContainer = styled.div`
  ${constrictedContainer};

  display: flex;
  flex: 1;

  padding: 150px 15px 0;

  margin-bottom: 60px;

  @media (max-width: ${props => props.theme.sizes.faq.large}px) {
    flex-direction: column;
    padding: 100px 20px 0 20px;
  }
`

export const QuestionsAnswers = styled.div`
  width: 65%;

  p {
    padding: 0 30px 0 0;
  }

  @media (max-width: ${props => props.theme.sizes.faq.large}px) {
    width: 100%;
  }
`

export const AboutFaq = styled.div`
  padding-right: 50px;
  width: 35%;

  a {
    text-decoration: underline;
    cursor: pointer;
    line-height: 1.3em;
    color: ${props => props.theme.colours.darkGrey};
  }

  a:visited {
    color: ${props => props.theme.colours.main};
  }

  h1 {
    font-size: 1.9em;
    font-weight: 700;

    margin-bottom: 20px;
  }

  p {
    font-weight: 100;
    font-size: 1em;
    line-height: 1.3em;
    color: ${props => props.theme.colours.darkGrey};

    margin-bottom: 40px;
  }

  @media (max-width: ${props => props.theme.sizes.faq.large}px) {
    padding: 0;
    width: 100%;
  }
`
