import ReactMarkdown from "react-markdown"
import styled from "styled-components"
import { Link } from "react-router-dom"

export const TestimonialItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Content = styled.div`
  transform: translateY(-55px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;

  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));

  @media (min-width: 600px) {
    height: 100%;
  }
`

export const LogoContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  aspect-ratio: 1 / 1;
  border-bottom-right-radius: 16px;
  background-color: #fff;

  overflow: hidden;

  min-height: 120px;
  height: 120px;

  padding: 10px;

  z-index: 2;

  box-sizing: border-box;

  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.26);

  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;

  :hover {
    transform: scale(1.015);
  }

  img {
    max-width: 95%;
  }
`

export const Text = styled.div`
  padding: 75px 20px 35px;
  line-height: 1.4;
`

export const Name = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 1.1em;
`

export const Markdown = styled(ReactMarkdown)`
  margin: 15px 0 7px;
  font-size: 1em;
`

export const Url = styled.a`
  padding: 15px 0;
  font-weight: bold;
  text-align: center;
  width: 100%;
  background-color: ${({ theme }) => theme.testimonials.nameBackground};
  color: ${props => props.theme.testimonials.nameColour};
  text-decoration: none;
`

export const ReadMoreLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  text-transform: capitalize;
  color: #000;
  line-height: 1.4;

  :visited {
    color: #000;
  }
`
