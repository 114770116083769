import React from "react"

const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&")

const getPage = (pages, pageId) => pages.filter(({ slug }) => slug === pageId)[0]

const useScrollTo = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
}

const useScrollToOnChange = content => React.useEffect(() => window.scrollTo(0, 0), [content])

const useResize = () => {
  const [pageWidth, setPageWidth] = React.useState(window.innerWidth)

  React.useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth)

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return pageWidth
}

const resizeContentfulImage = (url, width, height, quality = 90) => `${url}?w=${width}&h=${height}&q=${quality}`
const setQualityContentfulImage = (url, quality) => `${url}?q=${quality}`

const getMenu = (translations, hasNews, hasTestimonials, hasDownloads) => [
  ...(hasNews
    ? [
        {
          text: `${translations.news}`,
          to: `/${translations.news.toLowerCase()}`,
          matchPath: `/${translations.news.toLowerCase()}`
        }
      ]
    : []),
  {
    text: `${translations.brands}`,
    to: `/${translations.brands.toLowerCase()}`,
    matchPath: `/${translations.brands.toLowerCase()}`
  },
  ...(hasTestimonials
    ? [
        {
          text: `${translations.testimonials}`,
          to: `/${translations.testimonials.toLowerCase()}`,
          matchPath: `/${translations.testimonials.toLowerCase()}`
        }
      ]
    : []),
  {
    text: `${translations.faq.toUpperCase()}`,
    to: `/${translations.faq}`,
    matchPath: `/${translations.faq}`
  },
  ...(hasDownloads
    ? [
        {
          text: translations.downloads,
          to: `/${translations.downloads}`,
          matchPath: `/${translations.downloads}`
        }
      ]
    : []),
  {
    text: `${translations.contact}`,
    to: "/#contact",
    smoothScroll: true,
    highlightOnClick: false
  }
]

const downloadFolder = async (url, description) => {
  const folder = await fetch(url)
  const folderBlog = await folder.blob()
  const folderURL = URL.createObjectURL(folderBlog)

  const link = document.createElement("a")
  link.href = folderURL
  link.download = description
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const sortByOrder = array => {
  array.sort((a, b) => {
    if (a.order === null) {
      return 1
    }
    if (b.order === null) {
      return -1
    }
    return a.order > b.order ? 1 : -1
  })
  return array
}

const createBrandString = brands => {
  let brandsString = brands.slice(0, 4).join(" • ")

  if (brands.length > 4) {
    brandsString += ` and ${brands.length - 4} more`
  }

  return brandsString
}

export {
  getPage,
  encode,
  useScrollTo,
  useScrollToOnChange,
  useResize,
  resizeContentfulImage,
  setQualityContentfulImage,
  getMenu,
  downloadFolder,
  sortByOrder,
  createBrandString
}
