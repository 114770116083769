import React from "react"
import PropTypes from "prop-types"
import { createBrandString } from "../../helpers"

import * as S from "./style"

const NewsTileSmall = ({ imageUrl, title, href, brands }) => {
  return (
    <S.NewsTileSmall to={href}>
      <S.ImageContainer>
        <img src={imageUrl} alt={title} />
      </S.ImageContainer>
      <S.InfoContainer>
        <S.Brands>{createBrandString(brands)}</S.Brands>
        <S.Title>{title}</S.Title>
      </S.InfoContainer>
    </S.NewsTileSmall>
  )
}

export default NewsTileSmall

NewsTileSmall.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.string).isRequired,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
}
