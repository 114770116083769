/* eslint-disable no-param-reassign */
import * as contentful from "contentful"

import { BRAND, MARKET, HOME_PAGE, NEWS, PREVIEW, FAQ, NEWSCATEGORY, TESTIMONIALS, TRANSLATIONS, PAGES, REGISTRATION } from "./constants"

import { sortByOrder } from "."

const {
  REACT_APP_CONTENTFUL_SPACE_ID: contentfulSpaceId,
  REACT_APP_CONTENTFUL_ACCESS_TOKEN: contentfulAccessToken,
  REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN: contentfulPreviewAccessToken,
  REACT_APP_MODE: mode
} = process.env

const isPreview = mode === PREVIEW
const host = isPreview ? "preview.contentful.com" : "cdn.contentful.com"
const accessToken = isPreview ? contentfulPreviewAccessToken : contentfulAccessToken

const fetchData = lang =>
  contentful
    .createClient({
      space: contentfulSpaceId,
      accessToken,
      host
    })
    .getEntries({ limit: 900 })
    .then(response => response.items.filter(({ fields: { language } }) => language === lang))
    .then(items => {
      const getFields = fieldName =>
        items.reduce(
          (
            sum,
            {
              fields,
              sys: {
                contentType: {
                  sys: { id }
                }
              }
            }
          ) => (fields && id === fieldName ? sum.concat(fields) : sum),
          []
        )

      const homePage = getFields(HOME_PAGE)[0]
      const news = getFields(NEWS).sort((a, b) => b.newsDate?.localeCompare(a.newsDate))
      let faq = getFields(FAQ)
      const categories = getFields(NEWSCATEGORY)
      const testimonialsList = getFields(TESTIMONIALS)[0]
      const { translations } = getFields(TRANSLATIONS)[0]
      const pages = getFields(PAGES)
      const brands = sortByOrder(getFields(BRAND))
      const markets = getFields(MARKET)
      const registration = getFields(REGISTRATION)

      if (faq) {
        faq = sortByOrder(faq)
      }

      const {
        logo: {
          fields: {
            file: { url }
          }
        },
        bannerSection = []
      } = homePage

      homePage.logo = url

      homePage.bannerSection = bannerSection.reduce((sum, { fields }) => {
        if (!fields) return sum

        return sum.concat({
          header: fields.bannerHeader,
          subHeader: fields.bannerSubHeader,
          media: fields.bannerImage.fields.file.url,
          mediaType: fields.bannerImage.fields.file.contentType,
          mobileMedia: fields.mobileMedia.fields.file.url,
          mobileMediaType: fields.mobileMedia.fields.file.contentType
        })
      }, [])

      brands.forEach(item => {
        const {
          brandLogo: {
            fields: {
              file: { url: logoUrl }
            }
          },
          brandPageLogo: { fields: { file: { url: brandPageLogoUrl } = "" } = "" } = "",
          brandImage: {
            fields: {
              file: { url: imageUrl }
            }
          },
          brandBannerImageDesktop: { fields: { file: { url: desktopImageUrl } = "" } = "" } = "",
          brandBannerImageMobile: { fields: { file: { url: mobileImageUrl } = "" } = "" } = "",
          feature1Title = "",
          feature1Icon: { fields: { file: { url: feature1Icon } = "" } = "" } = "",
          feature2Title = "",
          feature2Icon: { fields: { file: { url: feature2Icon } = "" } = "" } = "",
          feature3Title = "",
          feature3Icon: { fields: { file: { url: feature3Icon } = "" } = "" } = "",
          downloadableLogo: { fields: { file: { url: fileUrl, fileName: name } = "" } = "" } = ""
        } = item

        if (item.markets) {
          item.markets = item.markets.reduce((tot, curr) => {
            const market = markets.find(i => i.countryCode.toLowerCase() === curr && i.brandId === item.slug)
            if (market) {
              const countryFlag = market.countryFlag.fields.file.url
              const { countryCode } = market
              tot.push({ countryCode, countryFlag })
            }

            return tot
          }, [])
        }

        item.brandLogo = logoUrl
        item.brandPageLogo = brandPageLogoUrl
        item.brandImage = imageUrl
        item.brandBannerImageDesktop = desktopImageUrl
        item.brandBannerImageMobile = mobileImageUrl
        item.features = [
          {
            title: feature1Title,
            iconUrl: feature1Icon
          },
          {
            title: feature2Title,
            iconUrl: feature2Icon
          },
          {
            title: feature3Title,
            iconUrl: feature3Icon
          }
        ]
        item.downloadableLogo = fileUrl && { fileUrl, name }
      })

      const brandsLogos = brands.reduce((sum, { brandName, brandLogo }) => {
        return sum.concat({
          brandName,
          brandLogo
        })
      }, [])

      const downloads = brands.reduce(
        (sum, { brandName, brandLogo, downloadableLogo }) =>
          downloadableLogo
            ? sum.concat({
                brandName,
                brandLogo,
                downloadableLogo
              })
            : sum,
        []
      )

      const brandLoginOptions = getFields(BRAND)
        .reduce((tot, curr) => {
          const { loginUrls } = curr

          if (!loginUrls || loginUrls.length === 0) return tot

          loginUrls.map(({ name, loginUrl }) => tot.push({ name, loginUrl }))

          return tot
        }, [])
        .sort((a, b) => (b.name > a.name ? -1 : 1))

      homePage.brandsList = brands.reduce(
        (sum, { slug, brandLogo }) =>
          sum.concat({
            slug,
            brandLogo
          }),
        []
      )

      news.forEach(item => {
        const {
          newsThumbnail: { fields: { file: { url: thumbnail } = "" } = "" } = "",
          newsImage: { fields: { file: { url: image } = "" } = "" } = "",
          category = [],
          slug = "",
          downloadableLogos = []
        } = item

        item.slug = slug[slug.length - 1] === "/" ? slug.substr(0, slug.length - 2) : slug
        item.newsThumbnail = thumbnail
        item.newsImage = image
        item.category = category.reduce((sum, { fields }) => {
          if (!fields) return sum

          return sum.concat({
            categoryName: fields.categoryName,
            categoryType: fields.categoryType
          })
        }, [])
        item.categories = category.reduce((sum, { fields }) => (fields ? sum.concat(fields.categoryName) : sum), [])
        item.downloadableLogos = downloadableLogos.reduce(
          (sum, { fields }) => (fields ? sum.concat({ name: fields.file.fileName, url: fields.file.url, description: fields.description }) : sum),
          []
        )
      })

      homePage.news = news.reduce((sum, { hideFromNewsFeed, newsThumbnail, slug, newsTitle, category }) => {
        if (hideFromNewsFeed) return sum
        return sum.concat({
          slug,
          newsThumbnail,
          newsTitle,
          category
        })
      }, [])

      const testimonials = testimonialsList?.testimonialsList?.reduce((sum, { fields }) => {
        if (!fields) return sum
        return sum.concat(fields)
      }, [])

      testimonials.forEach(item => {
        if (item) {
          const { affiliateImage: { fields: { file: { url: affiliateImage } = "" } = "" } = "" } = item
          item.logo = affiliateImage
        }
      })

      homePage.testimonials = testimonials

      const footerArticles = news
        .reduce((sum, { hideFromNewsFeed, newsTitle, slug }) => {
          if (hideFromNewsFeed) return sum
          return sum.concat({
            title: newsTitle,
            slug
          })
        }, [])
        .slice(0, 3)

      const pagesLinks = pages.reduce(
        (sum, { pageName, slug }) =>
          sum.concat({
            pageName,
            slug
          }),
        []
      )

      const footerLinks = { pagesLinks, footerArticles, extraLinks: homePage.footerLinks, socialIcons: homePage.socialIcons }

      return {
        homePage,
        brands,
        markets,
        brandLoginOptions,
        brandsLogos,
        downloads,
        news,
        faq,
        categories,
        testimonials,
        translations,
        pages,
        registration,
        footerLinks
      }
    })

export default fetchData
