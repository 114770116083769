import styled, { css } from "styled-components"

import { fadeInOut } from "../../styles/animation"

export const RaceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 15px 0;

  background-color: #ededed;
`

export const Race = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: content-box;

  align-self: center;

  margin: 30px 15px 15px;
  padding: 15px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 350px;

  ${props =>
    props.isHidden &&
    css`
      visibility: hidden;
    `};

  @media (max-width: 768px) {
    min-width: unset;
    width: 100%;
  }
`

const errorColour = "#e61a1a"

const inputSharedStyling = css`
  box-sizing: border-box;

  width: 100%;
  height: 40px;

  margin-bottom: 40px;

  background-color: transparent;

  border: none;
  border-bottom: 2px solid #8b8b8b;

  color: #000;

  font-size: 1.125em;
  font-style: italic;

  ::placeholder {
    color: #000;
    text-transform: capitalize;
  }

  :focus {
    outline: none;
    border-color: #000;

    color: ${props => props.theme.colours.black};
  }

  ${props =>
    props.error &&
    css`
      border-color: ${errorColour};

      color: ${errorColour};

      ::placeholder {
        color: ${errorColour};
      }

      :focus {
        border-color: ${errorColour};

        color: ${errorColour};
      }
    `}
`

export const Alert = styled.div`
  position: absolute;
  top: 40px;

  padding: 0.5rem 1.25rem;

  border: 1px solid transparent;
  border-radius: 0.25rem;

  opacity: 0;

  animation: 1s ${fadeInOut} ease-out 0.5s forwards, 1s ${fadeInOut} ease-out 2.5s forwards reverse;

  ${props =>
    props.type === "success" &&
    css`
      background-color: #d4edda;

      border-color: #c3e6cb;

      color: #155724;
    `}

  ${props =>
    props.type === "error" &&
    css`
      background-color: #f8d7da;

      border-color: #f5c6cb;

      color: #721c24;
    `}
`

export const TextInput = styled.input.attrs({
  type: "text"
})`
  ${inputSharedStyling};

  display: inline-block;
`

export const EmailInput = styled.input.attrs({
  type: "email"
})`
  ${inputSharedStyling};
`

export const HiddenInput = styled.input`
  display: none;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: rgba(237, 237, 237, 0.8);

  text-align: center;

  span:first-child {
    font-size: 2em;
    font-weight: bold;
  }

  span:nth-child(2) {
    margin-top: 10px;

    font-size: 2em;
    color: ${props => props.theme.colours.main};
  }
`

export const Leaderboard = styled.div`
  display: flex;
  flex-direction: column;

  margin: 20px 0;
`

export const LeaderboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  width: 100%;
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Title = styled.h2`
  margin-bottom: 30px;

  font-size: 2.5em;
  text-align: center;
`

export const CenteredText = styled.p`
  margin-bottom: 20px;

  text-align: center;
`

export const UpdatedOn = styled(CenteredText)`
  margin-top: -15px;

  font-size: 1em;
`

export const LeaderboardEntries = styled.div`
  display: flex;
  flex-direction: column;
`

export const LeaderboardEntry = styled.div`
  position: relative;

  display: flex;
  /* justify-content: space-between; */
  align-items: center;

  height: 64px;
  min-width: 250px;

  padding: 0 20px;

  background-color: #e9e9e9;

  :first-child {
    background-color: #e3e2de;

    transform: scale(1.1);

    span,
    p {
      font-weight: bold;
    }

    @media (max-width: 768px) {
      transform: scale(1);
    }
  }

  :not(:last-child) {
    margin-bottom: 20px;
  }
`

export const Placing = styled.span`
  font-size: 2em;
`

export const Nickname = styled.p`
  margin-left: 40px;

  font-size: 1.5em;
`

export const FirstPlaceSquare = styled.div`
  position: absolute;

  ${props =>
    props.position === "left"
      ? css`
          top: -10px;
          left: -10px;

          border-top: 3px solid ${props.theme.colours.main};
          border-left: 3px solid ${props.theme.colours.main};
        `
      : css`
          right: -10px;
          bottom: -10px;

          border-right: 3px solid ${props.theme.colours.main};
          border-bottom: 3px solid ${props.theme.colours.main};
        `}

  width: 64px;
  height: 64px;
`
