import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const TestimonialItem = ({ reducedText, readMoreLink, data }) => {
  const { logo, affiliateName, text, url } = data

  const textLimit = 250

  return (
    <S.TestimonialItem>
      <S.LogoContainer href={`https://${url}`} target="_blank">
        <img src={`${logo}?fm=webp&q=90&w=95`} alt="" />
      </S.LogoContainer>

      <S.Content>
        <S.Text>
          <S.Name>{affiliateName}</S.Name>
          <S.Markdown>{reducedText && text.length > 255 ? `${text.slice(0, textLimit - 1)}...` : text}</S.Markdown>
          {reducedText && text.length > textLimit && <S.ReadMoreLink to={readMoreLink.url}>{readMoreLink.label}</S.ReadMoreLink>}
        </S.Text>
        <S.Url href={`https://${url}`} target="_blank">
          {url}
        </S.Url>
      </S.Content>
    </S.TestimonialItem>
  )
}

TestimonialItem.propTypes = {
  data: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    affiliateName: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  reducedText: PropTypes.bool,
  readMoreLink: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
}

TestimonialItem.defaultProps = {
  reducedText: false
}

export default TestimonialItem
