import styled, { css } from "styled-components"

export const Button = styled.button`
  display: block;

  position: relative;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  margin: auto;

  background-color: ${props => (props.$isAltColour ? props.theme.colours.black : props.theme.colours.main)};
  border: none;

  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.fontSize}em;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  line-height: ${props => props.height}px;
  color: #fff;

  cursor: pointer;

  ${props =>
    props.icon &&
    css`
    padding: 0 22px 0 15px;

    background: url(${props.icon}) ${props.$isAltColour ? props.theme.colours.black : props.theme.colours.main};};
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 87% 50%;

    text-align: left;
  `}

  :disabled {
    opacity: 0.5;

    cursor: initial;
  }
`
