import * as PropTypes from "prop-types"
import React from "react"

import TestimonialItem from "../../components/TestimonialItem"
import Title from "../../components/Title"
import PageTemplate from "../PageTemplate"
import * as S from "./style"

const Testimonials = ({ testimonials, translations }) => {
  return (
    <PageTemplate type="testimonial">
      <S.TestimonialsContainer>
        <S.Header>
          <S.HeaderContent>
            <Title>{translations.testimonials}</Title>
          </S.HeaderContent>
        </S.Header>
        <S.TestimonialsContainerInner>
          {testimonials.map((item, ind) => (
            <TestimonialItem
              key={ind}
              data={item}
              readMoreLink={{
                label: translations.readMore,
                url: translations.testimonials.toLowerCase()
              }}
            />
          ))}
        </S.TestimonialsContainerInner>
      </S.TestimonialsContainer>
    </PageTemplate>
  )
}

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.any).isRequired,
  translations: PropTypes.objectOf(PropTypes.any)
}

Testimonials.defaultProps = {
  translations: {}
}

export default Testimonials
