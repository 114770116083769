import PropTypes from "prop-types"
import React, { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

import Dropdown from "../../components/Dropdown"
import NewsGridItem from "../../components/NewsGridItem"
import Search from "../../components/Search"
import Title from "../../components/Title"
import { useScrollTo } from "../../helpers"
import PageTemplate from "../PageTemplate"
import * as S from "./style"

const articlesPerPage = 8
const defaultFilter = {
  value: "all",
  label: "All brands"
}

const NewsPage = ({ news, categories, translations }) => {
  useScrollTo()
  const [page, setPage] = useState(1)

  const brandFilters = categories.reduce((tot, cur) => {
    if (cur.categoryType !== "brands" || tot.find(x => x.label === cur.categoryName)) {
      return tot
    }

    return [
      ...tot,
      {
        value: cur.categoryName.toLowerCase(),
        label: cur.categoryName
      }
    ]
  }, [])

  const createPages = (defaultArticles, searchText, isSearchActive, brandFilter, otherFilter) => {
    let articles = defaultArticles
    const searchQuery = searchText.toLowerCase()

    if (isSearchActive) {
      articles = articles.filter(
        article => article.newsTitle.toLowerCase().indexOf(searchQuery) > -1 || article.newsText.toLowerCase().indexOf(searchQuery) > -1
      )
    }

    if (brandFilter !== defaultFilter) {
      articles = articles.filter(
        article => article.category.length > 0 && article.category.some(item => item.categoryName.toLowerCase() === brandFilter.value)
      )
    }

    if (otherFilter !== defaultFilter) {
      articles = articles.filter(
        article => article.category.length > 0 && article.category.some(item => item.categoryName.toLowerCase() === otherFilter.value)
      )
    }

    return {
      articles: articles.slice((page - 1) * articlesPerPage, (page - 1) * articlesPerPage + articlesPerPage),
      totalArticles: articles.length
    }
  }

  const updateQueryString = (history, searchText, brandFilter, otherFilter) => {
    const parameters = []

    if (searchText !== "") {
      parameters.push(`search=${searchText}`)
    }

    if (brandFilter.value !== defaultFilter.value) {
      parameters.push(`brand=${brandFilter.value}`)
    }

    if (otherFilter.value !== defaultFilter.value) {
      parameters.push(`type=${otherFilter.value}`)
    }

    history.replace(`?${parameters.join("&")}`)
  }
  const params = new URLSearchParams(useLocation().search)

  const [defaultArticles] = React.useState(news.filter(x => !x.hideFromNewsFeed))

  const [searchText, setSearchText] = React.useState(params.get("search") || "")

  const getFilterFromParam = type => {
    const param = params.get(type)

    if (!param) return defaultFilter

    return { value: param.toLowerCase(), label: param }
  }

  const [brandFilter, setBrandFilter] = React.useState(getFilterFromParam("brand"))
  const [otherFilter, setOtherFilter] = React.useState(getFilterFromParam("type"))

  const isSearchActive = searchText !== ""

  const isBrandFilterActive = brandFilter !== defaultFilter
  const isOtherFilterActive = otherFilter !== defaultFilter

  const history = useHistory()

  const handleSearchChange = e => {
    setSearchText(e.target.value)

    updateQueryString(history, e.target.value, brandFilter, otherFilter)
  }

  const handleSearchClear = () => {
    setSearchText("")

    updateQueryString(history, "", brandFilter, otherFilter)
  }

  const handleBrandFilterChange = selectedFilter => {
    setBrandFilter(selectedFilter)

    updateQueryString(history, searchText, selectedFilter, otherFilter)
  }

  const handleClearAll = () => {
    setSearchText("")
    setBrandFilter(defaultFilter)
    setOtherFilter(defaultFilter)

    updateQueryString(history, "", defaultFilter, defaultFilter)
  }

  const pages = createPages(defaultArticles, searchText, isSearchActive, brandFilter, otherFilter)

  const { articles, totalArticles } = pages

  const handlePrevPageClick = () => {
    setPage(curPage => curPage - 1)
    window.scrollTo(0, 0)
  }

  const handleNextPageClick = () => {
    setPage(curPage => curPage + 1)
    window.scrollTo(0, 0)
  }

  return (
    <PageTemplate>
      <S.News id="news">
        <Title>{translations.news}</Title>
        <S.Options>
          <Search
            text={searchText}
            onChange={handleSearchChange}
            onClear={handleSearchClear}
            shouldFlex
            marginRight="15px"
            border="1px solid #8b8b8b"
            translations={translations}
          />
          <Dropdown
            className="filter-dropdown"
            isMulti={false}
            options={[defaultFilter, ...brandFilters]}
            value={brandFilter}
            onChange={handleBrandFilterChange}
          />
        </S.Options>
        {articles.length > 0 && (
          <S.ArticleGrid>
            {articles.map((article, ind) => (
              <NewsGridItem key={ind} data={article} size="auto" translations={translations} />
            ))}
          </S.ArticleGrid>
        )}
        {(isSearchActive || isBrandFilterActive || isOtherFilterActive) && articles.length === 0 && (
          <S.NoResultsFound>
            <h3>{translations.noArticlesFound}</h3>
            <S.ClearResultsButton onClick={handleClearAll}>{translations.clear}</S.ClearResultsButton>
          </S.NoResultsFound>
        )}
        {articles.length > 0 && (
          <S.Pagination>
            <button type="button" disabled={page === 1} onClick={handlePrevPageClick}>
              {`<`}
            </button>
            {page} / {Math.ceil(totalArticles / articlesPerPage)}
            <button type="button" disabled={page === Math.ceil(totalArticles / articlesPerPage)} onClick={handleNextPageClick}>
              {`>`}
            </button>
          </S.Pagination>
        )}
      </S.News>
    </PageTemplate>
  )
}

NewsPage.propTypes = {
  news: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  translations: PropTypes.objectOf(PropTypes.any)
}

NewsPage.defaultProps = {
  translations: {}
}

export default NewsPage
