import styled from "styled-components"

import { constrictedContainer, markdownStyling } from "../../styles/globalStyle"

export const PolicyContainer = styled.div`
  ${constrictedContainer};

  flex: 1;

  padding-top: 120px;

  font-family: ${props => props.theme.fonts.primary};

  div {
    ${markdownStyling}
  }

  div:last-child {
    margin-bottom: 30px;

    border-bottom: 1px solid #e9e9e9;
  }
`
