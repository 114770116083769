import PropTypes from "prop-types"
import React from "react"

import Title from "../../components/Title"
import { useScrollTo } from "../../helpers"
import PageTemplate from "../PageTemplate"
import * as S from "./style"
import BrandTileSmall from "../../components/BrandTileSmall"

const BrandsPage = ({ brands, translations }) => {
  useScrollTo()

  const brandComponents = brands.map(({ brandLogo, slug }) => (
    <BrandTileSmall key={slug} logoUrl={brandLogo} href={`/${translations.brands.toLowerCase()}/${slug}`} />
  ))

  return (
    <PageTemplate>
      <S.BrandContainer>
        <Title>{translations.brands}</Title>
        <S.BrandInner>{brandComponents}</S.BrandInner>
      </S.BrandContainer>
    </PageTemplate>
  )
}

BrandsPage.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.any),
  translations: PropTypes.objectOf(PropTypes.any)
}

BrandsPage.defaultProps = {
  brands: {},
  translations: {}
}

export default BrandsPage
