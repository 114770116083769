import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const BrandItem = ({ imageSrc, routePath }) => (
  <S.BrandItem to={routePath} aria-label="Brand">
    <S.BrandImage src={imageSrc} alt="brand logo" />
  </S.BrandItem>
)

BrandItem.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  routePath: PropTypes.string.isRequired
}

export default BrandItem
