import styled from "styled-components"

import { constrictedContainer } from "../../styles/globalStyle"

export const BrandContainer = styled.div`
  ${constrictedContainer};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  flex: 1;

  margin: 120px auto;

  a {
    width: 330px;
    height: 170px;

    margin: 20px;
    padding: 20px;

    @media (max-width: 457px) {
      width: calc(100vw - 110px);
    }
  }
`

export const BrandInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`
