import styled from "styled-components"

export const DownloadableLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  margin: 50px 0;
`

export const DownloadTextContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.9);
  border-bottom-right-radius: 16px;

  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
`

export const DownloadText = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  text-align: center;

  span {
    font-weight: bold;
    font-size: 0.9em;
    text-transform: uppercase;

    color: #000;

    margin: 0 auto;
    padding: 10px;
  }
`

export const DownloadableLogo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  aspect-ratio: 1 / 1;
  border-bottom-right-radius: 16px;
  background-color: #fff;
  cursor: pointer;
  padding: 10px;

  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));

  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;

  :hover {
    transform: scale(1.015);

    ${DownloadTextContainer} {
      visibility: visible;
      opacity: 1;

      transition: all 0.3s ease-in-out;
    }
  }

  @media (min-width: 1280px) {
    max-height: 176px;
    padding: 12px;
  }

  img {
    max-width: 95%;
    max-height: 50%;
  }
`
