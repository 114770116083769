import styled from "styled-components"
import { Link } from "react-router-dom"

export const BrandTileSmall = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  aspect-ratio: 1 / 1;
  border-bottom-right-radius: 16px;
  background-color: #fff;
  padding: 10px;

  box-sizing: border-box;

  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));

  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;

  :hover {
    transform: scale(1.015);
  }

  img {
    max-width: 95%;
    max-height: 50%;
  }
`
