import * as PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link } from "react-router-dom"

import { useScrollTo } from "../../helpers"
import { brandColorData } from "../../styles/brandsColor"
import PageTemplate from "../PageTemplate"
import BrandHeader from "../../components/BrandHeader"
import RegisterButton from "../../components/RegisterButton"
import * as S from "./style"

const BrandPage = ({ brand, translations, registrationForm }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useScrollTo()

  const {
    brandName,
    slug,
    brandDescription,
    brandBannerImageDesktop,
    brandBannerImageMobile,
    markets,
    seoTitle,
    seoDescription,
    features,
    brandUrl,
    showRegisterButton
  } = brand

  const colours = brandColorData[slug]

  const handleCtaClick = () => window.open(brandUrl, "_blank", "noopener")

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768)

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const bannerImg = isMobile ? brandBannerImageMobile : brandBannerImageDesktop

  return (
    <PageTemplate>
      <Helmet>
        <title data-react-helmet="true">{seoTitle}</title>
        <meta name="description" content={seoDescription} data-react-helmet="true" />
      </Helmet>
      <BrandHeader brand={brand} translations={translations} />
      <S.BrandBanner src={bannerImg} alt="brand hero banner" />
      <S.FeatureStrip colour={colours.background}>
        <S.FeatureStripInner>
          {features.map((feature, ind) => (
            <S.Feature key={ind} textColour={colours.featureStrip.text}>
              <S.FeatureIcon>
                <LazyLoadImage src={feature.iconUrl} alt="Brand Feature" />
              </S.FeatureIcon>
              <p>{feature.title}</p>
            </S.Feature>
          ))}
        </S.FeatureStripInner>
      </S.FeatureStrip>

      <S.BrandContainer>
        <S.About>
          <S.AboutInner>
            <S.Description>{brandDescription}</S.Description>
            {markets?.length > 0 && (
              <S.MarketsContainer>
                <S.MarketTitle>Markets</S.MarketTitle>
                <S.MarketsInner>
                  {markets.map((market, ind) => (
                    <S.Market key={ind} index={ind}>
                      <Link to={`./${slug}/${market.countryCode}`}>
                        <S.FlagImage src={market.countryFlag} />
                        <span>{market.countryCode}</span>
                      </Link>
                    </S.Market>
                  ))}
                </S.MarketsInner>
              </S.MarketsContainer>
            )}
          </S.AboutInner>
          <S.ButtonContainer>
            {showRegisterButton && (
              <RegisterButton options={registrationForm} size="medium" label={translations.register} to={`/${translations.register.toLowerCase()}`} />
            )}
            {
              // temporary fix for betmgm having an extra site url for NL which doesn't redirect automatically - will need to find a solution
              brandName === "BetMGM" ? (
                <>
                  <S.CtaButton brand={slug} colour="#000" onClick={() => window.open("https://www.betmgm.co.uk", "_blank", "noopener")}>
                    Visit Betmgm.co.uk
                  </S.CtaButton>
                  <S.CtaButton brand={slug} colour="#000" onClick={() => window.open("https://www.betmgm.nl", "_blank", "noopener")}>
                    Visit Betmgm.nl
                  </S.CtaButton>
                </>
              ) : (
                <S.CtaButton brand={slug} colour="#000" onClick={handleCtaClick}>
                  Visit {brandName}
                </S.CtaButton>
              )
            }
          </S.ButtonContainer>
        </S.About>
      </S.BrandContainer>
    </PageTemplate>
  )
}

BrandPage.propTypes = {
  brand: PropTypes.objectOf(PropTypes.any),
  translations: PropTypes.any,
  registrationForm: PropTypes.objectOf(PropTypes.any).isRequired
}

BrandPage.defaultProps = {
  brand: {},
  translations: {}
}

export default BrandPage
