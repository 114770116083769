import styled, { css, keyframes } from "styled-components"

export const CookieConsent = styled.div`
  position: fixed;
  inset: 0;
  z-index: 999;

  display: flex;
  flex-direction: column-reverse;

  background-color: rgba(0, 0, 0, 0.65);
`

const slideUpAnim = keyframes`
from {
  transform: translateY(100%);
}
to {
  transform: translateY(0);
}`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;

  ${({
    theme: {
      cookieConsent: { bgColour }
    }
  }) => css`
    background-color: ${bgColour};
  `}

  animation: ${slideUpAnim} 0.25s ease-in-out forwards;
`

export const InfoPanel = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px;
  gap: 16px;

  @media (min-width: 900px) {
    flex-direction: row;
  }

  ${({
    theme: {
      cookieConsent: {
        cookieIcon: { bgColour, colour }
      }
    }
  }) => css`
    svg {
      min-width: 70px;
      min-height: 70px;
    }

    svg > rect {
      fill: ${bgColour};
    }

    svg > path {
      fill: ${colour};
    }
  `}
`

export const Disclaimer = styled.div`
  display: flex;

  gap: 16px;

  @media (min-width: 900px) {
    max-width: 60%;
  }
`

export const DisclaimerText = styled.p`
  display: inline-block;
  flex-grow: 1;

  ${({
    theme: {
      cookieConsent: {
        disclaimer: {
          colour,
          link: { colour: linkColour }
        }
      }
    }
  }) =>
    css`
      ${colour && `color: ${colour ?? "#fff"}`};

      a {
        text-decoration: none;
        color: ${linkColour};
      }
    `}

  line-height: 1.2;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex-grow: 1;

  @media (min-width: 600px) {
    display: flex;
  }

  gap: 16px;

  ${({ $isManagementPanelOpen }) =>
    $isManagementPanelOpen &&
    css`
      display: none;
    `}
`

const getButton = key => css`
  ${({
    theme: {
      cookieConsent: {
        buttons: {
          [key]: { colour, bgColour, hover, borderRadius, maxHeight, padding, boxShadow, width }
        }
      }
    }
  }) =>
    css`
      ${colour && `color: ${colour}`};
      ${bgColour && `background-color: ${bgColour}`};
      ${borderRadius && `border-radius: ${borderRadius}`};
      ${maxHeight && `max-height: ${maxHeight}`};
      ${padding && `padding: ${padding}`};
      ${boxShadow && `box-shadow: ${boxShadow}`};
      ${width && `width: ${width}`};

      :hover {
        ${hover?.bgColour && `background-color: ${hover.bgColour}`};
      }
    `}

  height: 48px;
  border: none;
  font-weight: bold;
  cursor: pointer;

  ${({
    theme: {
      cookieConsent: {
        buttons: { fontSize, textTransform }
      }
    }
  }) => css`
    font-size: ${fontSize};
    text-transform: ${textTransform};
  `}
`

export const ManageButton = styled.button`
  ${getButton("manage")}
`

export const ConfirmButton = styled.button`
  ${getButton("confirm")}
`

export const AllowButton = styled.button`
  ${getButton("allowAll")}
`

export const ManagementButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 8px;
  margin-top: 16px;

  ${ConfirmButton} {
    width: 100%;
  }

  ${AllowButton} {
    width: 100%;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

export const ManagePanel = styled.div`
  display: flex;
  flex-direction: column;

  ${({
    theme: {
      cookieConsent: { borderColour }
    }
  }) => css`
    border-top: ${borderColour} 1px solid;
  `}

  padding: 16px;
`

export const Toggles = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  gap: 12px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
  }
`
