import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const BrandItem = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.075);

  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);

  transition: transform 0.1s ease-in-out;

  :hover {
    border: 1px solid rgba(0, 0, 0, 1);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);

    transform: scale(1.025);
    transition: transform 0.1s ease-in-out box-shadow 0.1s ease-in-out;
  }
`

export const BrandImage = styled(LazyLoadImage)`
  max-width: 85%;
  max-height: 65%;
`
