import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import { markdownStyling } from "../../styles/globalStyle"

const maxWidth = "1100px"

export const MarketContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${props => props.backgroundColor};
`

export const About = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  padding: 20px 0;
`

export const AboutInner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${maxWidth};
  width: 100%;

  padding: 0 15px;
`

export const Description = styled(ReactMarkdown)`
  max-width: ${maxWidth};
  flex-direction: column;

  padding: 0 15px 50px;

  font-size: 1.1em;
  color: ${props => props.textColor};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  background: #fff;
  justify-content: center;
  width: 100%;

  padding-top: 30px;

  line-height: 1.3;
  color: #000;

  @media (max-width: 900px) {
    padding: 10px 0 30px;
  }
  p {
    font-size: 1.1em;
  }

  h4 {
    font-size: 1.3em;
    text-transform: uppercase;

    padding: 10px 0 50px;
    letter-spacing: 0.1em;
  }
`

export const ContentInner = styled(ReactMarkdown)`
  ${markdownStyling}
  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  max-width: ${maxWidth};
  width: 100%;

  padding: 0 20px 50px;

  color: #3b3b3b;

  p {
    font-size: 0.9em;
  }

  h4 {
    font-size: 1.1em;
    text-transform: uppercase;

    padding: 30px 0 10px;
    letter-spacing: 0.1em;
    margin-bottom: 0;
  }

  ul {
    li {
      margin-left: 16px;

      list-style: disc;

      :last-child {
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 0 15px;
    width: auto;
  }
`
