import { useEffect, useState } from "react"
import { pushConsentToDataLayer } from "../helpers/gtmManager"

const Dimension = {
  WIDTH: "width",
  HEIGHT: "height"
}

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: 0,
    height: 0
  })

  useEffect(() => {
    const handleWindowResize = () => setScreenSize({ width: window.innerWidth, height: window.innerHeight })

    window.addEventListener("resize", handleWindowResize)

    handleWindowResize()

    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  const between = (dimension, from, to) => (!from || !to ? null : screenSize[dimension] >= from && screenSize[dimension] <= to)
  const lessThan = (dimension, number) => number && screenSize[dimension] < number
  const lessThanOrEqualTo = (dimension, number) => number && screenSize[dimension] <= number
  const greaterThan = (dimension, number) => number && screenSize[dimension] > number
  const greaterThanOrEqualTo = (dimension, number) => number && screenSize[dimension] >= number
  const isMobile = screenSize[Dimension.WIDTH] <= 512

  return {
    width: {
      value: screenSize.width,
      between: (from, to) => between(Dimension.WIDTH, from, to),
      lessThan: value => lessThan(Dimension.WIDTH, value),
      lessThanOrEqualTo: value => lessThanOrEqualTo(Dimension.WIDTH, value),
      greaterThan: value => greaterThan(Dimension.WIDTH, value),
      greaterThanOrEqualTo: value => greaterThanOrEqualTo(Dimension.WIDTH, value)
    },
    height: {
      value: screenSize.height,
      between: (from, to) => between(Dimension.HEIGHT, from, to),
      lessThan: value => lessThan(Dimension.HEIGHT, value),
      lessThanOrEqualTo: value => lessThanOrEqualTo(Dimension.HEIGHT, value),
      greaterThan: value => greaterThan(Dimension.HEIGHT, value),
      greaterThanOrEqualTo: value => greaterThanOrEqualTo(Dimension.HEIGHT, value)
    },
    isMobile
  }
}

/**
 * A hook that handles whether the cookie consent is visible or not, as well as setting the cookie/ls for it.
 * @returns An object containing whether the consent should be visible or not, and a callback function for accepting
 */
export const useCookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false)
  const [shouldLoadGtm, setShouldLoadGtm] = useState(false)

  const createExpiryDate = () => {
    const expiryDate = new Date()
    expiryDate.setDate(expiryDate.getDate() + 183)
    return expiryDate
  }

  const accept = consent => {
    const gtagConsentMode = {
      security_storage: consent.necessary ? "granted" : "denied",
      functionality_storage: consent.functional ? "granted" : "denied",
      ad_storage: consent.marketing ? "granted" : "denied",
      analytics_storage: consent.analytics ? "granted" : "denied",
      personalization_storage: consent.necessary ? "granted" : "denied",
      ad_user_data: consent.marketing ? "granted" : "denied",
      ad_personalization: consent.marketing ? "granted" : "denied"
    }

    const lsConsentMode = {
      ...gtagConsentMode,
      expiry: createExpiryDate()
    }

    pushConsentToDataLayer("consent", "update", gtagConsentMode)

    localStorage.setItem("consentMode", JSON.stringify(lsConsentMode))

    setShowConsent(false)
    setShouldLoadGtm(true)
  }

  useEffect(() => {
    const consentMode = JSON.parse(localStorage.getItem("consentMode"))

    if (consentMode) {
      const expiryDate = Date.parse(consentMode.expiry)

      if (Date.now() < expiryDate) {
        setShouldLoadGtm(true)
        return
      }
    }

    setShowConsent(true)
  }, [])

  return {
    showConsent,
    shouldLoadGtm,
    accept
  }
}
