import * as PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Media from "react-media"
import { Link } from "react-router-dom"
import breaks from "remark-breaks"
import gfm from "remark-gfm"

import NewsGridItem from "../../components/NewsGridItem"
import NewsListItem from "../../components/NewsListItem"
import RaceForm from "../../components/RaceForm"
import { useScrollTo, downloadFolder } from "../../helpers"
import { useScreenSize } from "../../utils/hooks"
import PageTemplate from "../PageTemplate"
import * as S from "./style"
import Button from "../../components/Button"

import iconFolder from "../../assets/icons/icon-folder.svg"

const getArticleContent = (article, translations, isMobile) => {
  const { hasRace, newsText, downloadableLogos } = article

  const renderImage = ({ src, alt }) => {
    if ((alt === "mobile" && isMobile) || (alt === "desktop" && !isMobile)) {
      return <S.ImageRendererNoGap isMobile={isMobile} src={src} alt="" />
    }

    if (alt !== "mobile" && alt !== "desktop") {
      return <S.ImageRenderer src={src} alt={alt} />
    }

    return null
  }

  const plugins = [breaks, gfm]
  const renderers = { image: renderImage }

  if (hasRace) {
    const splitTexts = newsText.split("~RACEFORM~")

    if (splitTexts.length === 2) {
      const { slug, raceTitle, raceStartDate, raceEndDate, raceLeaderboardTitle, raceLeaderboard, raceLeaderboardTwoTitle, raceLeaderboardTwo } =
        article

      const leaderboards = [
        { title: raceLeaderboardTitle, leaderboard: raceLeaderboard },
        { title: raceLeaderboardTwoTitle, leaderboard: raceLeaderboardTwo }
      ]

      return (
        <>
          <S.Markdown source={splitTexts[0]} renderers={renderers} plugins={plugins} />
          <RaceForm
            slug={slug}
            title={raceTitle}
            startDate={raceStartDate}
            endDate={raceEndDate}
            leaderboards={leaderboards}
            translations={translations}
          />
          <S.Markdown source={splitTexts[1]} renderers={renderers} plugins={plugins} />
        </>
      )
    }
  }

  return (
    <>
      <S.Markdown source={newsText} renderers={renderers} allowDangerousHtml plugins={plugins} />
      {downloadableLogos && (
        <S.DownloadableFilesContainer isMobile={isMobile}>
          {downloadableLogos.map((item, i) => {
            return (
              <>
                <S.DownloadableFiles key={`_${i}`}>
                  {item.description && <span>{item.description}</span>}
                  <Button icon={iconFolder} size="medium" isAltColour onClick={() => downloadFolder(item.url, item.name)}>
                    Download
                  </Button>
                </S.DownloadableFiles>
              </>
            )
          })}
        </S.DownloadableFilesContainer>
      )}
    </>
  )
}

const NewsArticlePage = ({ article, related, translations }) => {
  useScrollTo()

  const screenSize = useScreenSize()

  const { seoTitle, seoDescription } = article

  const content = getArticleContent(article, translations, screenSize.width.lessThanOrEqualTo(650))

  return (
    <PageTemplate>
      <Helmet>
        <title data-react-helmet="true">{seoTitle}</title>
        <meta name="description" content={seoDescription} data-react-helmet="true" />
      </Helmet>
      <S.NewsArticle>
        <S.Banner>
          <S.BannerInner>
            <LazyLoadImage src={article.newsImage} alt="Article" />
            {/* {screenSize.width.greaterThanOrEqualTo(768) && <S.BannerTitle>{article.newsTitle}</S.BannerTitle>} */}
          </S.BannerInner>
          {/* {screenSize.width.lessThan(768) && <S.MobileTitle>{article.newsTitle}</S.MobileTitle>} */}
        </S.Banner>
        <S.ContentContainer>
          <S.Date>
            {`${article.newsDate}`}
            {article.categories.length > 0 && ` - ${article.categories.join(", ")}`}
          </S.Date>
          <S.BannerTitle>{article.newsTitle}</S.BannerTitle>
          <S.ArticleContent>
            {content}
            <S.ContactText>
              {`${translations.newsArticleContactText} `}
              <a href={`mailto:${translations.newsArticleContactEmail}`}>{translations.newsArticleContactEmail}</a>
            </S.ContactText>
          </S.ArticleContent>
          {related.length > 0 && (
            <S.Related>
              <S.RelatedHeader>
                <h3>{translations.relatedArticles}</h3>
                <Link to="/news">{`${translations.more} >`}</Link>
              </S.RelatedHeader>

              <S.RelatedArticles>
                <Media query={{ maxWidth: 982 }}>
                  {matches =>
                    matches
                      ? related.map((relatedArticle, ind) => <NewsListItem key={ind} data={relatedArticle} translations={translations} />)
                      : related.map((relatedArticle, ind) => <NewsGridItem key={ind} data={relatedArticle} translations={translations} />)
                  }
                </Media>
              </S.RelatedArticles>
            </S.Related>
          )}
        </S.ContentContainer>
      </S.NewsArticle>
    </PageTemplate>
  )
}

NewsArticlePage.propTypes = {
  article: PropTypes.objectOf(PropTypes.any),
  related: PropTypes.arrayOf(PropTypes.any),
  translations: PropTypes.objectOf(PropTypes.any)
}
NewsArticlePage.defaultProps = {
  article: {},
  related: {},
  translations: {}
}

export default NewsArticlePage
