import styled from "styled-components"

import { constrictedContainer } from "../../styles/globalStyle"

export const LogosInner = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
  gap: 20px;

  @media (min-width: 512px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 400px;

  margin: 60px 0 20px;
  padding-top: 60px;

  background: ${props => props.theme.news.previewInfoBg};

  @media (max-width: ${props => props.theme.sizes.faq.large}px) {
    height: 330px;
  }

  @media (max-width: ${props => props.theme.sizes.faq.medium}px) {
    height: 290px;
  }

  @media (max-width: ${props => props.theme.sizes.faq.small}px) {
    height: 260px;
  }
`

export const DownloadableLogoContainer = styled.div`
  ${constrictedContainer};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  flex: 1;

  margin: 120px auto;

  a {
    width: 330px;
    height: 170px;

    margin: 20px;
    padding: 20px;

    @media (max-width: 457px) {
      width: calc(100vw - 110px);
    }
  }
`
