import styled from "styled-components"
import { Link } from "react-router-dom"

export const NewsTileSmall = styled(Link)`
  display: flex;
  column-gap: 16px;

  color: #fff;
  text-decoration: none;

  @media (min-width: 900px) {
    flex-direction: column;
    row-gap: 10px;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const ImageContainer = styled.div`
  column-gap: 0;
  row-gap: 16px;

  font-size: 1.25rem;

  width: 125px;
  height: 125px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 900px) {
    width: auto;
    height: auto;

    img {
      width: auto;
      height: auto;
    }
  }

  :hover {
    img {
      transform: scale(1.015);
    }
  }

  img {
    aspect-ratio: 1 / 1;
    width: 100%;

    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 100ms;
  }
`

export const Title = styled.p``

export const Brands = styled.p`
  color: #b3b3b3;
  font-size: 0.95em;
`
