import PropTypes from "prop-types"
import React from "react"
import ReactMarkdown from "react-markdown"

import Button from "../Button"
import HomeSection from "../HomeSection"
import Title from "../Title"
import * as S from "./style"

const About = ({
  data: {
    config,
    content: { title, text, ctaText }
  }
}) => (
  <HomeSection id="about" config={config}>
    <S.About>
      <S.TextContainer>
        <Title>{title}</Title>
        <S.Text>
          <ReactMarkdown source={text} />
        </S.Text>
      </S.TextContainer>
      <Button size="large" to="/register" gtmData={{ action: "register", label: "middle" }}>
        {ctaText}
      </Button>
    </S.About>
  </HomeSection>
)

About.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      bgColor: PropTypes.string,
      bgTitle: PropTypes.string,
      bgTitleColor: PropTypes.string,
      outboundLink: PropTypes.string,
      outboundText: PropTypes.string,
      outboundLinkColor: PropTypes.string
    }),
    content: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      ctaText: PropTypes.string
    })
  })
}

About.defaultProps = {
  data: PropTypes.objectOf(PropTypes.any).isRequired
}

export default About
