import * as PropTypes from "prop-types"
import React from "react"
import ReactMarkdown from "react-markdown"
import breaks from "remark-breaks"

import Title from "../../components/Title"
import { useScrollToOnChange } from "../../helpers"
import PageTemplate from "../PageTemplate"
import * as S from "./style"

const InfoPage = ({ page }) => {
  const { title, content, content2 } = page

  useScrollToOnChange(content)

  return (
    <PageTemplate>
      <S.PolicyContainer>
        <Title>{title}</Title>
        <div>
          <ReactMarkdown source={content} plugins={[breaks]} />
        </div>
        {content2 && (
          <div>
            <ReactMarkdown source={content2} plugins={[breaks]} />
          </div>
        )}
      </S.PolicyContainer>
    </PageTemplate>
  )
}

InfoPage.propTypes = {
  page: PropTypes.arrayOf(PropTypes.any).isRequired
}

export default InfoPage
