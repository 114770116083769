import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const Search = ({ text, onChange, onClear, shouldFlex, width, height, marginTop, marginRight, marginBottom, marginLeft, border, translations }) => (
  <S.Search
    shouldFlex={shouldFlex}
    width={width}
    height={height}
    marginTop={marginTop}
    marginRight={marginRight}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    border={border}
  >
    <input type="text" value={text} placeholder={translations.search} onChange={onChange} />
    {text !== "" && <S.ClearButton onClick={onClear} />}
  </S.Search>
)

Search.propTypes = {
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  shouldFlex: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  marginTop: PropTypes.string,
  marginRight: PropTypes.string,
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  border: PropTypes.string,
  translations: PropTypes.objectOf(PropTypes.any)
}

Search.defaultProps = {
  shouldFlex: false,
  width: "0px",
  height: "0px",
  marginTop: "0px",
  marginRight: "0px",
  marginBottom: "0px",
  marginLeft: "0px",
  border: "none",
  translations: {}
}

export default Search
