import PropTypes from "prop-types"
import React from "react"
import ReactMarkdown from "react-markdown"
import { Link } from "react-router-dom"

import { ReactComponent as FacebookSVG } from "../../assets/icons/social/facebook.svg"
import { ReactComponent as InstagramSVG } from "../../assets/icons/social/instagram.svg"
import { ReactComponent as TwitterSVG } from "../../assets/icons/social/twitter.svg"
import * as S from "./style"

const Footer = ({ footerLinks: { pagesLinks, extraLinks, socialIcons }, navLinks, translations }) => {
  return (
    <S.Footer>
      <S.Inner>
        <S.Section>
          <h3>{translations.navigation}</h3>
          <ul>
            {navLinks.map(link => (
              <li key={link.text}>
                <Link to={link.to}>{link.text}</Link>
              </li>
            ))}
          </ul>
        </S.Section>
        <S.Section>
          <h3>{translations.legal}</h3>
          <ul>
            {pagesLinks
              .sort((a, b) => a.pageName.localeCompare(b.pageName))
              .map(({ slug, pageName }, index) => (
                <li key={index}>
                  <Link to={`/${slug}`}>{pageName}</Link>
                </li>
              ))}
          </ul>
        </S.Section>
        <S.Section>
          <h3>{translations.ourSites}</h3>
          <ReactMarkdown linkTarget="_blank" source={extraLinks} />
        </S.Section>
        {socialIcons && (
          <S.Section>
            {socialIcons[0] && (
              <S.SocialMediaLink href={socialIcons[0].url} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <FacebookSVG alt="facebook" />
              </S.SocialMediaLink>
            )}
            {socialIcons[1] && (
              <S.SocialMediaLink href={socialIcons[1].url} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                <TwitterSVG alt="twitter" />
              </S.SocialMediaLink>
            )}
            {socialIcons[2] && (
              <S.SocialMediaLink href={socialIcons[2].url} target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                <InstagramSVG alt="instagram" />
              </S.SocialMediaLink>
            )}
          </S.Section>
        )}
      </S.Inner>
    </S.Footer>
  )
}

Footer.propTypes = {
  footerLinks: PropTypes.objectOf(PropTypes.any),
  navLinks: PropTypes.arrayOf(PropTypes.any),
  translations: PropTypes.objectOf(PropTypes.any)
}

Footer.defaultProps = {
  footerLinks: {},
  navLinks: {},
  translations: {}
}

export default Footer
