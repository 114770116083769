import casibeeBgImage from "../assets/images/brands/casibee/bg.png"
import jackpotGuruBgImage from "../assets/images/brands/jackpotguru/bg.png"
import liveCasinoBgImage from "../assets/images/brands/livecasino/marble-effect.png"
import royalpandaBgImage from "../assets/images/brands/royalpanda/bg.png"

export const brandColorData = {
  pixelbet: {
    main: "#5914c2",
    background: "#281443",
    featureStrip: {
      background: "#5914c2",
      text: "#fff"
    },
    bottom: "#221134",
    cta: "#ff00af",
    text: "#fff"
  },
  leovegas: {
    main: "#962e15",
    background: "#F18825",
    featureStrip: {
      background: "#F18825",
      text: "#fff"
    },
    bottom: "#fe6328",
    cta: "rgba(253,153,28,1)",
    text: "#fff"
  },
  betmgm: {
    main: "#000",
    background: "#000",
    featureStrip: {
      background: "#000",
      text: "#fff"
    },
    bottom: "#fe6328",
    cta: "#000",
    text: "#fff"
  },
  gogocasino: {
    main: "#f446b2",
    background: "#120e0a",
    featureStrip: {
      background: "#febf00",
      text: "#fff"
    },
    bottom: "#120e0a",
    cta: "#120e0a",
    text: "#fff"
  },
  livecasino: {
    main: `url(${liveCasinoBgImage})`,
    background: "#031B1C",
    featureStrip: {
      background: "#F6C4C4",
      text: "#000"
    },
    bottom: `url(${liveCasinoBgImage})`,
    cta: "#F6C4C4",
    text: "#fff"
  },
  royalpanda: {
    main: "#B1070A",
    background: `url(${royalpandaBgImage})`,
    featureStrip: {
      background: "#B1070A",
      text: "#fff"
    },
    bottom: "#292423",
    cta: "#B1070a",
    text: "#fff"
  },
  pinkcasino: {
    main: "#000",
    background: "#2e1a37",
    featureStrip: {
      background: "#000",
      text: "#fff"
    },
    bottom: "#452b51",
    cta: "#FF1A7A",
    text: "#fff"
  },
  twentyone: {
    main: "#55bee8",
    background: "#0d1319",
    featureStrip: {
      background: "#55bee8",
      text: "#fff"
    },
    bottom: "#0d1319",
    cta: "#55bee8",
    text: "#fff"
  },
  expekt: {
    main: "#FE8000",
    background: "#000",
    featureStrip: {
      background: "#FE8000",
      text: "#fff"
    },
    bottom: "#000",
    cta: "#FE8000",
    text: "#fff"
  },
  castlejackpot: {
    main: "#D0AB60",
    background: "#1e1d13",
    featureStrip: {
      background: "#D0AB60",
      text: "#000"
    },
    bottom: "#1e1d13",
    cta: "#FE8000",
    text: "#fff"
  },
  slotboss: {
    main: "#28A3A3",
    background: "#034348",
    featureStrip: {
      background: "#28A3A3",
      text: "#fff"
    },
    bottom: "#034348",
    cta: "#28A3A3",
    text: "#fff"
  },
  betuk: {
    main: "#1765A5",
    background: "#193655",
    featureStrip: {
      background: "#1765A5",
      text: "#fff"
    },
    bottom: "#193655",
    cta: "#193655",
    text: "#fff"
  },
  jackpotguru: {
    main: "#59B74F",
    background: `url(${jackpotGuruBgImage})`,
    featureStrip: {
      background: "#F47640",
      text: "#fff"
    },
    bottom: "#8D2883",
    cta: "#59B74F",
    text: "#8D2883"
  },
  casibee: {
    main: "#F7D00C",
    background: `url(${casibeeBgImage}), rgb(0 0 0 / 91%)`,
    featureStrip: {
      background: "#7656A1",
      text: "#fff"
    },
    bottom: "#300F42",
    cta: "#F7D00C",
    text: "#fff"
  }
}
