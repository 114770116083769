import { LazyLoadImage } from "react-lazy-load-image-component"
import styled from "styled-components"

import { smallConstrictedContainer } from "../../styles/globalStyle"

export const MarketTitle = styled.div`
  padding-bottom: 5px;

  font-size: 3em;
  color: ${props => props.textColor};

  @media (max-width: 768px) {
    font-size: 1.7em;
  }
`

export const Breadcrumbs = styled.div`
  display: flex;
  padding-bottom: 20px;
  color: ${props => props.textColor};

  span {
    padding-right: 5px;
  }

  a {
    color: ${props => props.textColor};
    text-underline-offset: 2px;
  }
`
export const BreadCrumbsArrow = styled.span`
  display: none;

  text-underline-offset: 2px;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;

    position: absolute;
    left: 10px;

    top: 50%;
    bottom: 50%;

    img {
      width: 35px;
      transform: rotate(90deg);

      filter: invert(1);
    }
  }
`

export const BreadCrumbsPath = styled.div`
  display: block;

  a {
    color: ${props => props.textColor};
    text-underline-offset: 2px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

export const BrandHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 60px;

  background: ${props => props.colour};
`

export const HeaderInner = styled.div`
  ${smallConstrictedContainer}
  width: 100%;
  padding: 15px 40px 15px 20px;

  position: relative;
  min-height: 90px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    height: auto;
    min-height: 75px;
    padding: 15px 20px;

    flex-direction: ${props => (props.isMarketPage ? "column-reverse" : "row")};
    justify-content: center;
  }
`
export const Logo = styled(LazyLoadImage)`
  max-height: 60px;
  max-width: 245px;

  @media (max-width: 768px) {
    max-width: 165px;
    max-height: 40px;
  }
`

export const HeaderContent = styled.div``
