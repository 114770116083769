import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import * as S from "./style"
import Toggle from "../Toggle"
import { ThemeContext } from "styled-components"

const ManagedCookieConsent = ({
  onAccept,
  disclaimer,
  cookiePolicyLink,
  acceptButtonText,
  allowAllButtonText,
  confirmButtonText,
  manageButtonText,
  necessary,
  functional,
  analytics,
  marketing
}) => {
  const [isManagementPanelOpen, setIsManagementPanelOpen] = useState(false)
  const [consent, setConsent] = useState({
    necessary: "granted",
    functional: "granted",
    analytics: "granted",
    marketing: "granted"
  })

  const handleManageButtonClick = () => setIsManagementPanelOpen(true)
  const handleConfirmClick = () => onAccept(consent)
  const handleAllowAllClick = () =>
    onAccept({
      necessary: "granted",
      functional: "granted",
      analytics: "granted",
      marketing: "granted"
    })
  const handleChange = (key, value) => setConsent({ ...consent, [key]: value })

  const toggleTheme = useContext(ThemeContext).cookieConsent.toggles

  return (
    <S.CookieConsent>
      <S.Inner>
        <S.InfoPanel>
          <S.Disclaimer>
            <svg width="70" height="70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect xmlns="http://www.w3.org/2000/svg" width="70" height="70" rx="4" fill="#F1F0EC" />
              <path
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.965 18.25h.694l1.4 4.988 3.65.002.36 3.318 5.372.119-.001 4.03.53.085 1.281.217 1.26.226 1.856.351.11.618c.165.912.244 1.844.244 2.803 0 9.254-7.502 16.755-16.756 16.755-9.254 0-16.755-7.501-16.755-16.755 0-9.1 7.253-16.505 16.294-16.75l.461-.006Zm-.69 1.848-.187.01c-7.834.454-14.047 6.95-14.047 14.899 0 8.242 6.682 14.924 14.924 14.924 8.243 0 14.925-6.682 14.925-14.924 0-.285-.008-.566-.024-.843l-.03-.415-.066-.602-.59-.111-1.244-.223-1.265-.214-1.288-.208-.773-.121-.002-3.802-5.186-.112-.359-3.286-3.39.002-1.398-4.974Zm8.63 22.046a2.572 2.572 0 1 0 0-5.144 2.572 2.572 0 0 0 0 5.144Zm0-1.85a.722.722 0 1 1 0-1.444.722.722 0 0 1 0 1.444Zm-15.094-6.139a2.572 2.572 0 1 1-5.144 0 2.572 2.572 0 0 1 5.144 0Zm-3.294 0a.722.722 0 1 0 1.444 0 .722.722 0 0 0-1.444 0Zm9.555 4.24a2.572 2.572 0 1 0 0-5.145 2.572 2.572 0 0 0 0 5.144Zm0-1.85a.722.722 0 1 1 0-1.445.722.722 0 0 1 0 1.444Zm1.706 8.194a2.572 2.572 0 1 1-5.145 0 2.572 2.572 0 0 1 5.145 0Zm-3.295 0a.722.722 0 1 0 1.445 0 .722.722 0 0 0-1.445 0Zm-1.91-15.012a2.572 2.572 0 1 0 0-5.144 2.572 2.572 0 0 0 0 5.144Zm0-1.85a.722.722 0 1 1-.001-1.444.722.722 0 0 1 0 1.444Z"
                fill="#0B0B0B"
              />
            </svg>
            <S.DisclaimerText>
              {disclaimer}
              <a href="/cookie-policy">{cookiePolicyLink}</a>
            </S.DisclaimerText>
          </S.Disclaimer>
          <S.Buttons $isManagementPanelOpen={isManagementPanelOpen}>
            {isManagementPanelOpen ? (
              <S.ConfirmButton onClick={handleConfirmClick}>{confirmButtonText}</S.ConfirmButton>
            ) : (
              <S.ManageButton onClick={handleManageButtonClick}>{manageButtonText}</S.ManageButton>
            )}
            <S.AllowButton onClick={handleAllowAllClick}>{isManagementPanelOpen ? allowAllButtonText : acceptButtonText}</S.AllowButton>
          </S.Buttons>
        </S.InfoPanel>
        {isManagementPanelOpen && (
          <S.ManagePanel>
            <S.Toggles>
              <Toggle
                key="necessary"
                onChange={value => handleChange("necessary", value)}
                isOn={consent.necessary}
                disabled
                title={necessary.title}
                body={necessary.description}
                styling={toggleTheme}
              />
              <Toggle
                key="functional"
                onChange={value => handleChange("functional", value)}
                isOn={consent.functional}
                title={functional.title}
                body={functional.description}
                styling={toggleTheme}
              />
              <Toggle
                key="analytics"
                onChange={value => handleChange("analytics", value)}
                isOn={consent.analytics}
                title={analytics.title}
                body={analytics.description}
                styling={toggleTheme}
              />
              <Toggle
                key="marketing"
                onChange={value => handleChange("marketing", value)}
                isOn={consent.marketing}
                title={marketing.title}
                body={marketing.description}
                styling={toggleTheme}
              />
            </S.Toggles>
            <S.ManagementButtons>
              <S.ConfirmButton onClick={handleConfirmClick}>{confirmButtonText}</S.ConfirmButton>
              <S.AllowButton onClick={handleAllowAllClick}>{isManagementPanelOpen ? allowAllButtonText : acceptButtonText}</S.AllowButton>
            </S.ManagementButtons>
          </S.ManagePanel>
        )}
      </S.Inner>
    </S.CookieConsent>
  )
}

ManagedCookieConsent.propTypes = {
  onAccept: PropTypes.func.isRequired,
  disclaimer: PropTypes.string.isRequired,
  acceptButtonText: PropTypes.string.isRequired,
  allowAllButtonText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  manageButtonText: PropTypes.string.isRequired,
  cookiePolicyLink: PropTypes.string.isRequired,
  necessary: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  functional: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  analytics: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  marketing: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired
}

export default ManagedCookieConsent
