import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { markdownStyling } from "../../styles/globalStyle"

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(0, 0, 0, 0.8);
`

export const RegisterInfoPopup = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-end;

  max-width: 600px;
  max-height: calc(100vh - 60px - 120px);

  overflow-y: scroll;

  @media (max-width: 600px) {
    position: fixed;
    inset: 60px 0 0 0;

    max-height: calc(100vh - 60px);
  }

  padding: 32px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  background-color: #fff;
`

export const Text = styled(ReactMarkdown)`
  ${markdownStyling};

  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;

    li {
      padding-left: 1em;
      text-indent: -0.7em;
    }

    li:before {
      content: "• ";
      font-size: 1.5em;
      color: #bda770;
    }
  }

  h2 {
    color: #bda770;
    margin-bottom: 30px;
  }
`

export const Button = styled.button`
  background-color: #bda770;
  box-shadow: 0 1px 2px rgb(0 0 0 / 0.1);
  border: none;
  padding: 10px 15px;
  font-weight: bold;

  color: #fff;
  text-transform: uppercase;

  :hover {
    cursor: pointer;
  }
`
