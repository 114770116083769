import styled, { css } from "styled-components"

export const Title = styled.h3`
  display: inline-block;

  margin-bottom: 25px;

  font-family: ${props => props.theme.fonts.primary};
  font-size: 1.9em;
  font-weight: 700;
  text-transform: uppercase;

  ${props =>
    props.isInverted
      ? css`
          color: #fff;
        `
      : css`
          color: ${props.theme.colours.black};
        `}

  @media (max-width: 846px) {
    /* margin: 0 auto 25px auto; */
    width: 100%;
    text-align: center;
  }
`
