import styled from "styled-components"

export const Testimonials = styled.div`
  display: flex;
  justify-content: center;
  background: url("/assets/images/seamless-dark.jpg") #000;
  background-attachment: fixed;
  padding: 64px 0;
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  padding: 0 24px;
`

export const TestimonialsContainer = styled.div`
  display: grid;
  grid-auto-flow: row;

  grid-template-columns: minmax(0, 1fr);
  gap: 32px;

  padding-top: 30px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 32px;
  }

  @media (max-width: 768px) {
    > div:nth-child(3) {
      display: none;
    }
  }

  @media (max-width: 600px) {
    > div:nth-child(2) {
      display: none;
    }
  }
`
